<ion-header>
  <ion-toolbar class="toolbar-background">
    <!-- Map Nav Bar-->
    <ion-buttons class="tabbar-tile-start" slot="start">
      <arm-disarm-button></arm-disarm-button>
    </ion-buttons>
    <ion-buttons class="tabbar-col-center">
      <top-bar-title
        class="tabbar-title-overflow toolbar-title"
      ></top-bar-title>
    </ion-buttons>
    <ion-buttons class="tabbar-tile-end" slot="end">
      <bluetooth-button></bluetooth-button>
      <!-- Must wrap signal-indicator in buttons to get correct position-->
      <ion-button fill="clear" class="top-bar_signal toolbar-button">
        <signal-indicator></signal-indicator>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content
  no-padding
  no-bounce
  forceOverscroll="false"
  [class.graph_stats-expanded-background]="statsExpanded"
>
  <panel-container>
    <ng-container *ngIf="!statsExpanded">
      <div
        [ngClass]="{ 'segment_ios': isIOSDevice}"
        class="graph_segment-container"
      >
        <ion-segment [ngModel]="nHoursOfDataString$ | async">
          <ng-container *ngFor="let option of dateRangeOptions">
            <ion-segment-button
              value="{{option.value}}"
              (click)="setNHoursData(option.value)"
            >
              {{option.label}}
            </ion-segment-button>
          </ng-container>
        </ion-segment>
      </div>
      <top-panel>
        <swiper
          [pagination]="{clickable: true, dynamicBullets: true, dynamicMainBullets: 6}"
          class="top-panel_inner graph_slide_buttons"
        >
          <ng-template
            swiperSlide
            *ngFor="let graph of (graphs | async | orderGraphs)"
            class="graph_slider_slide"
          >
            <div class="graph_slide-container">
              <div class="graph_top-bar">
                <span class="graph_unit"
                  >{{graph?.unit === 'C' || graph?.unit === 'F' ? "°" :
                  ""}}{{graph?.unit}}</span
                >
                <div class="graph_title">{{graph?.name}}</div>
                <ion-fab-button
                  class="graph_refresh"
                  size="small"
                  (click)="refresh()"
                >
                  <ion-icon
                    name="refresh"
                    class="graph_refresh-btn_icon"
                    [class.graph_refresh-btn_icon__spin]="isGraphLoading$ | async"
                  ></ion-icon>
                </ion-fab-button>
              </div>
              <div
                class="graph_canvas-container"
                [class.graph_canvas-container__no-data]="graph?.noData"
              >
                <canvas
                  class="graph-canvas"
                  *ngIf="graph?.data && graph?.datetimes && !graph?.noData; else noData"
                  baseChart
                  [data]="graph.data"
                  [type]="chartType"
                  [options]="graph.chartConfig"
                ></canvas>
                <ng-template #noData>
                  <div class="graph_canvas_no-data">
                    <ion-icon name="alert-circle"></ion-icon>
                    <h4>No data in range</h4>
                  </div>
                </ng-template>
              </div>
            </div>
          </ng-template>
        </swiper>
      </top-panel>
    </ng-container>
    <bottom-panel class="graph_background">
      <stats-grid (expandedChange)="toggleStatsExpanded()"></stats-grid>
    </bottom-panel>
  </panel-container>
</ion-content>
