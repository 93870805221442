import { select } from '@angular-redux/store'
import {
  Component,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core'
import { AlertController } from '@ionic/angular'
import { Observable, Subject, takeUntil } from 'rxjs'

import { AppDevice } from '../../models/app-device.model'
import { DeviceProvider } from '../../services/device/device.service'
import { SideEffectsProvider } from '../../services/side-effects/side-effects.service'
import { AuthProvider } from '../../services/auth/auth.service'
import { HelpersProvider } from '../../services/helpers/helpers.service'

import { now } from 'moment'
import { AgmMap, AgmMarker } from '@agm/core'
import { Coord } from '../../models/coord.model'
import { getDefaultZoom, getLatLngCenter } from '../../util'
import { SettingsProvider } from '../../services/settings/settings.service'
import { UserSettings } from '../../models-shared/user-settings.model'
import { Router } from '@angular/router'
import { AngularFireDatabase } from '@angular/fire/compat/database'
import { DbPathsProvider } from 'app/services/db-paths/db-paths.service'
import { BluetoothLEProvider } from 'app/services/bluetooth-le/bluetooth-le.service'
import { EnvironmentProvider } from 'app/services/environment/environment.service'

@Component({
  selector: 'select-device',
  templateUrl: './select-device.page.html',
  styleUrls: ['./select-device.page.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class SelectDevicePage implements OnDestroy {
  editOpen: boolean = false

  lastUpdated: { [key: string]: number } = {}
  @select('allDevices') allDevices$: Observable<AppDevice[]>

  @ViewChild('map') map: AgmMap
  @ViewChildren(AgmMarker) markers: QueryList<AgmMarker>
  userSettings: UserSettings
  markerZIndex: { [key: string]: number } = {}

  // map attributes
  zoomLevel: number = 7
  expanded: boolean = false

  // map position set to brnkl office
  // but will be over-written once map loads
  latitude: number = 48.4242
  longitude: number = -123.302383
  updatedPosition: Coord = {
    lat: 48.4242,
    long: -123.302383,
  }
  updatedZoom: number = 4

  loadMap: boolean = false
  isMobileDevice: boolean

  private ngUnsubscribe = new Subject<void>()

  constructor(
    private router: Router,
    private alertCtrl: AlertController,
    private sideEffects: SideEffectsProvider,
    private deviceProvider: DeviceProvider,
    private settingsProvider: SettingsProvider,
    private db: AngularFireDatabase,
    private paths: DbPathsProvider,
    private auth: AuthProvider,
    private environment: EnvironmentProvider,
    private helpers: HelpersProvider,
    private bluetoothProvider: BluetoothLEProvider
  ) {
    this.isMobileDevice = this.environment.isNativeApp()
    if (!this.isMobileDevice) this.loadMap = true
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  ngOnInit(): void {
    // subscribe to user settings
    this.settingsProvider.userSettings$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((userSettings: UserSettings) => {
        this.userSettings = userSettings
      })

    this.allDevices$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((devices: AppDevice[]) => {
        for (const device of devices) {
          this.db
            .object(`${this.paths.deviceSettings(device.deviceId)}/lastUpdated`)
            .valueChanges()
            .subscribe((val: number) => {
              this.lastUpdated[device.deviceId] = val
            })
        }
      })
    // disconnect bluetooth
    if (this.bluetoothProvider.connected.value) {
      try {
        this.bluetoothProvider.disconnectFromMate(false)
      } catch (err) {
        console.log('Couldnt disconnect from bluetooth connection...')
      }
    }
  }

  // load map after view has been initialized
  ionViewWillEnter(): void {
    if (!this.loadMap) {
      setTimeout(() => {
        this.loadMap = true
      }, 0)
    }
  }

  // mapReady is called when map is ready to be rendered.
  // Generates default position based on markers if user
  // has not updated default view yet
  mapReady(map): void {
    if (
      this.userSettings.fleetViewCenter != null &&
      this.userSettings.fleetViewZoom != null
    ) {
      this.latitude = this.userSettings.fleetViewCenter.lat
      this.longitude = this.userSettings.fleetViewCenter.long
      this.zoomLevel = this.userSettings.fleetViewZoom
    } else {
      let markerArray = this.markers.filter(
        (coord) => coord.latitude !== null || coord.longitude !== null
      )
      // check if only one device with gps data
      if (markerArray.length > 1) {
        let center = getLatLngCenter(this.markers.toArray())
        this.latitude = center.lat
        this.longitude = center.long
        this.zoomLevel = getDefaultZoom(this.markers.toArray())
      } else if (markerArray.length === 1) {
        this.latitude = this.markers.toArray()[0].latitude
        this.longitude = this.markers.toArray()[0].longitude
        this.zoomLevel = 10
      }
    }
  }

  centerChange(coords: { lat: number; lng: number }): void {
    this.updatedPosition.lat = coords.lat
    this.updatedPosition.long = coords.lng
  }

  zoomChange(zoom): void {
    this.updatedZoom = zoom
  }

  toggleStatsExpanded() {
    this.expanded = !this.expanded
  }

  addVessel(): void {
    if (this.auth.isDemoAccount()) {
      this.helpers.showToast('Vessels cannot be added to the demo account.')
      return
    }

    // changed to angular routing using url string
    this.router.navigate(['add-device'])
  }

  deviceOffline(device): boolean {
    let lastUpdate = device.settings.lastUpdated
    // check if db has newer data (refresh bug)
    if (lastUpdate < this.lastUpdated[device.deviceId]) {
      lastUpdate = this.lastUpdated[device.deviceId]
    }

    // check if lastUpdated timestamp (in seconds) was 3 or more hours ago
    return lastUpdate < now() / 1000 - 10800
  }

  resizeMap(): void {
    if (this.map) {
      this.latitude = this.updatedPosition.lat
      this.longitude = this.updatedPosition.long
      this.map.triggerResize(true)
    }
  }

  pushTabsPage(device: AppDevice): void {
    this.mouseOver(device.settings.deviceName)
    this.sideEffects.setCurrentDeviceId(device)
  }

  toggleEdit(): void {
    this.editOpen = !this.editOpen
  }

  // Gets called when user clicks set view button.
  // Updates users settings with current map position
  async setDefaultView() {
    this.userSettings.fleetViewCenter = this.updatedPosition
    this.userSettings.fleetViewZoom = this.updatedZoom

    await this.settingsProvider.saveUserSettings(this.userSettings)

    this.helpers.showToast('Default map view saved!')
  }

  getCoordsFromMarkers(markers: AgmMarker[]): Coord[] {
    let coords: Coord[] = []
    for (let i = 0; i < markers.length; i++) {
      coords[i] = {
        lat: markers[i].latitude,
        long: markers[i].longitude,
      }
    }
    return coords
  }

  async unlinkDeviceCallback(deviceId: string): Promise<any> {
    if (this.auth.isDemoAccount()) {
      this.helpers.showToast('Vessel cannot be removed from the demo account.')
      return
    }

    await this.deviceProvider.unlinkDevice(deviceId)
  }

  unlinkDevice(deviceId: string): void {
    this.alertCtrl
      .create({
        header: 'Remove vessel?',
        buttons: [
          {
            text: 'Confirm',
            handler: () => {
              this.unlinkDeviceCallback(deviceId)
            },
          },
          {
            text: 'Cancel',
          },
        ],
      })
      .then((confirmAlert) => confirmAlert.present())
  }

  mouseOver(deviceName: string): void {
    // reset all markers then bring marker in focus to front
    Object.keys(this.markerZIndex).forEach(
      (key) => (this.markerZIndex[key] = 1)
    )
    this.markerZIndex[deviceName] = 10
  }
}
