import { Injectable } from '@angular/core'
import { AngularFireDatabase } from '@angular/fire/compat/database'
import { QueryFn } from '@angular/fire/compat/database/interfaces'

import { BehaviorSubject, lastValueFrom } from 'rxjs'
import { Observable, timer, of, combineLatest } from 'rxjs'
import {
  map,
  filter,
  switchMap,
  startWith,
  takeUntil,
  catchError,
} from 'rxjs/operators'

import { DbPathsProvider } from '../../services/db-paths/db-paths.service'
import { DeviceProvider } from '../../services/device/device.service'
import { CloudFunctionsProvider } from '../../services/cloud-functions/cloud-functions.service'

import { Alert } from '../../models-shared/alert.model'
import { AppAlert } from '../../models/app-alert.model'
import { CurrentIds } from '../../models/ids.model'

import { unixNow, oneMonth } from '../../util'

@Injectable({
  providedIn: 'root',
})
export class AlertsProvider {
  public isClearing$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  public activeAlerts$: Observable<Alert[]>

  private nInitialAlerts: number = 30
  private nPerScroll: number = 10
  private nVisibleAlerts$ = new BehaviorSubject(this.nInitialAlerts)

  private monthsToQuery$ = new BehaviorSubject(1)

  constructor(
    private db: AngularFireDatabase,
    private device: DeviceProvider,
    private paths: DbPathsProvider,
    private cloudFunctions: CloudFunctionsProvider
  ) {
    this.activeAlerts$ = this.getActiveAlerts()
  }

  public clearAlert(alert: AppAlert): Promise<any> {
    const ids = this.device.currentBRNKLandMateId$.getValue()
    const { isMate, ...cleanedAlert } = alert

    const clearedAlert = {
      [alert.dbKey]: {
        ...cleanedAlert,
        active: false,
      },
    }

    if (!alert.isMate) {
      return this.db
        .object(this.paths.alertsList(ids.deviceId))
        .update(clearedAlert)
    } else {
      return this.db
        .object(this.paths.mateAlertsList(ids.mateId))
        .update(clearedAlert)
    }
  }

  public async clearAll(): Promise<void> {
    const deviceId = this.device.currentBRNKLandMateId$.value.deviceId
    const path = `alerts/clearAll/${deviceId}`

    return this.cloudFunctions.authedPost<void>(path)
  }

  public reset() {
    this.nVisibleAlerts$.next(this.nInitialAlerts)
    this.monthsToQuery$.next(1)
  }

  public async appendToVisibleAlerts(): Promise<void> {
    await lastValueFrom(timer(1 * 1000))

    const nVisibleAlerts = this.nVisibleAlerts$.value
    this.nVisibleAlerts$.next(nVisibleAlerts + this.nPerScroll)
  }

  public getActiveAlerts(): Observable<AppAlert[] | null> {
    return this.device.currentBRNKLandMateId$.pipe(
      switchMap((currentIds) => {
        if (currentIds.deviceId == null && currentIds.mateId == null) {
          // No device
          return of(null)
        } else {
          return this.getAlertsFromDb(currentIds)
        }
      })
    )
  }

  public getAlertsSortAscending(): Observable<boolean> {
    return this.device.currentBRNKLandMateId$
      .pipe(
        map((ids) => ids.deviceId),
        filter((deviceId) => deviceId != null)
      )
      .pipe(
        switchMap((deviceId: string): Observable<boolean> => {
          return this.db
            .object<boolean>(this.paths.alertsSortAscending(deviceId))
            .valueChanges()
            .pipe(startWith(null), takeUntil(this.device.deviceUnselected$))
        }),
        startWith(null)
      )
  }

  public getAlertsFromDb(currentIds: CurrentIds) {
    const alertsSortingAscending$ = this.getAlertsSortAscending()
    const oldestValidAlertDatetime$ = this.getOldestValidAlertDatetime()

    return combineLatest([
      this.monthsToQuery$,
      this.nVisibleAlerts$,
      alertsSortingAscending$,
      oldestValidAlertDatetime$,
    ]).pipe(
      switchMap(
        ([
          monthsToQuery,
          nVisibleAlerts,
          sortAscending,
          oldestValidAlertDatetime,
        ]) => {
          const observables: Observable<AppAlert[]>[] = [of([])]
          const query = this.createQuery(
            sortAscending,
            monthsToQuery,
            oldestValidAlertDatetime
          )

          if (currentIds.deviceId != null) {
            observables.push(
              this.db
                .list<Alert>(this.paths.alertsList(currentIds.deviceId), query)
                .valueChanges()
                .pipe(
                  filter((vals): any => vals != null),
                  takeUntil(this.device.deviceUnselected$),
                  map((alerts) => {
                    return alerts
                      ? alerts.map((alert) => {
                          const appAlert = alert as AppAlert
                          appAlert.isMate = false
                          return appAlert
                        })
                      : []
                  })
                )
            )
          }

          if (currentIds.mateId != null) {
            observables.push(
              this.db
                .list<Alert>(
                  this.paths.mateAlertsList(currentIds.mateId),
                  query
                )
                .valueChanges()
                .pipe(
                  filter((vals): any => vals != null),
                  takeUntil(this.device.mateChanged$),
                  map((alerts) => {
                    return alerts
                      ? alerts.map((alert) => {
                          const appAlert = alert as AppAlert
                          appAlert.isMate = true
                          return appAlert
                        })
                      : []
                  }),
                  catchError((err) => of([]))
                )
            )
          }

          return combineLatest([...observables]).pipe(
            map((alertsArray) => {
              return {
                alertsArray,
                sortAscending,
                monthsToQuery,
                oldestValidAlertDatetime,
                nVisibleAlerts,
              }
            })
          )
        }
      ),
      map(
        ({
          alertsArray,
          sortAscending,
          monthsToQuery,
          oldestValidAlertDatetime,
          nVisibleAlerts,
        }) => {
          // Combine alert arrays
          const allAlerts: AppAlert[] = []
          for (const alerts of alertsArray) {
            allAlerts.push(...alerts)
          }

          const allVisibleAlerts = allAlerts.filter((k: AppAlert) => k.active)
          return {
            allVisibleAlerts,
            sortAscending,
            nVisibleAlerts,
            oldestValidAlertDatetime,
            monthsToQuery,
          }
        }
      ),
      filter(
        ({
          allVisibleAlerts,
          sortAscending,
          nVisibleAlerts,
          oldestValidAlertDatetime,
          monthsToQuery,
        }) => {
          // Check if the app need to re-query database
          if (!oldestValidAlertDatetime) {
            return true
          }

          if (allVisibleAlerts.length >= nVisibleAlerts) {
            return true
          }

          // Not enough alerts, can we query again?
          if (sortAscending) {
            const endAtDatetime =
              oldestValidAlertDatetime + oneMonth * monthsToQuery
            if (endAtDatetime < unixNow()) {
              // Re-query alerts
              this.monthsToQuery$.next(this.monthsToQuery$.value + 1)
              return false
            }
          } else {
            const startAtDatetime = unixNow() - oneMonth * monthsToQuery
            if (startAtDatetime > oldestValidAlertDatetime) {
              // Re-query alerts
              this.monthsToQuery$.next(this.monthsToQuery$.value + 1)
              return false
            }
          }

          return true
        }
      ),
      map(({ allVisibleAlerts, sortAscending, nVisibleAlerts }) => {
        // Sort alerts
        let sortingComparison: (a1: AppAlert, a2: AppAlert) => number
        if (sortAscending) {
          sortingComparison = (a1: AppAlert, a2: AppAlert) => {
            return a1.datetime - a2.datetime
          }
        } else {
          sortingComparison = (a1: AppAlert, a2: AppAlert) => {
            return a2.datetime - a1.datetime
          }
        }

        const sortedAlerts = allVisibleAlerts.sort(sortingComparison)

        // Trim array if it is too long
        if (sortedAlerts.length > nVisibleAlerts) {
          return sortedAlerts.slice(0, nVisibleAlerts)
        }

        return sortedAlerts
      })
    )
  }

  private createQuery = (
    sortAscending: boolean,
    monthsToQuery: number,
    oldestValidAlertDatetime: number
  ): QueryFn => {
    // Note alerts are always sorted in ascending order
    let query: QueryFn
    if (sortAscending) {
      const endAtDatetime = oldestValidAlertDatetime + oneMonth * monthsToQuery
      query = (ref) =>
        ref.orderByChild('datetime').startAt(0).endAt(endAtDatetime)
    } else {
      const startAtDatetime = unixNow() - oneMonth * monthsToQuery
      query = (ref) =>
        ref
          .orderByChild('datetime')
          .startAt(startAtDatetime)
          .endAt(Number.MAX_SAFE_INTEGER)
    }

    return query
  }

  private getOldestValidAlertDatetime(): Observable<number | null> {
    const deviceId$ = this.device.currentBRNKLandMateId$.pipe(
      map((ids) => ids.deviceId)
    )

    const mateId$ = this.device.currentBRNKLandMateId$.pipe(
      map((ids) => ids.mateId)
    )

    const oldestValidAlert$: Observable<number | null> = deviceId$.pipe(
      switchMap((deviceId) => {
        if (deviceId != null) {
          return this.db
            .list<Alert>(this.paths.alertsList(deviceId), (ref) =>
              ref.orderByChild('datetime').limitToFirst(1)
            )
            .valueChanges()
            .pipe(takeUntil(this.device.deviceUnselected$))
        } else {
          return of(null)
        }
      }),
      map((alerts) => {
        if (alerts != null && alerts.length > 0) {
          return alerts[0].datetime
        }

        return null
      })
    )

    const oldestValidMateAlert$: Observable<number | null> = mateId$.pipe(
      switchMap((mateId) => {
        if (mateId != null) {
          return this.db
            .list<Alert>(this.paths.mateAlertsList(mateId), (ref) =>
              ref.orderByChild('datetime').limitToFirst(1)
            )
            .valueChanges()
            .pipe(takeUntil(this.device.mateChanged$))
        } else {
          return of(null)
        }
      }),
      map((alerts) => {
        if (alerts != null && alerts.length > 0) {
          return alerts[0].datetime
        }

        return null
      })
    )

    const lastAllClearedDatetime$: Observable<number | null> = deviceId$.pipe(
      switchMap((deviceId) => {
        if (deviceId != null) {
          return this.db
            .object<number>(this.paths.alertsLastAllClearedDatetime(deviceId))
            .valueChanges()
            .pipe(startWith(null), takeUntil(this.device.deviceUnselected$))
        } else {
          return of(null)
        }
      }),
      startWith(null)
    )

    const mateLastAllClearedDatetime$: Observable<number | null> = mateId$.pipe(
      switchMap((mateId) => {
        if (mateId != null) {
          return this.db
            .object<number>(this.paths.mateAlertsLastAllClearedDatetime(mateId))
            .valueChanges()
            .pipe(startWith(null), takeUntil(this.device.mateChanged$))
        } else {
          return of(null)
        }
      }),
      startWith(null)
    )

    return combineLatest([
      oldestValidAlert$,
      oldestValidMateAlert$,
      lastAllClearedDatetime$,
      mateLastAllClearedDatetime$,
    ]).pipe(
      map(
        ([
          oldestValidAlert,
          oldestValidMateAlert,
          lastAllClearedDatetime,
          mateLastAllClearedDatetime,
        ]) => {
          // Check lastCleared properties first

          // Mate and BRNKL linked
          if (
            lastAllClearedDatetime != null &&
            mateLastAllClearedDatetime != null
          ) {
            return lastAllClearedDatetime < mateLastAllClearedDatetime
              ? lastAllClearedDatetime
              : mateLastAllClearedDatetime
          }

          if (
            lastAllClearedDatetime != null &&
            mateLastAllClearedDatetime == null
          ) {
            // Standalone BRNKL
            return lastAllClearedDatetime
          }

          // Alerts have never been cleared before
          if (oldestValidAlert != null && oldestValidMateAlert != null) {
            return oldestValidAlert < oldestValidMateAlert
              ? oldestValidAlert
              : oldestValidMateAlert
          }

          if (oldestValidAlert != null && oldestValidMateAlert == null) {
            // Standalone BRNKL
            return oldestValidAlert
          }

          // No alerts exist
          return null
        }
      )
    )
  }
}
