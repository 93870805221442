import { select } from '@angular-redux/store'
import { Component, ViewEncapsulation } from '@angular/core'

import { Observable, Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'

import { AppDevice } from '../../models/app-device.model'
import { SettingsProvider } from '../../services/settings/settings.service'
import { DeviceProvider } from '../../services/device/device.service'
import { BluetoothLEProvider } from '../../services/bluetooth-le/bluetooth-le.service'
import { EnvironmentProvider } from '../../services/environment/environment.service'
import { HelpersProvider } from '../../services/helpers/helpers.service'
import { AngularFireDatabase } from '@angular/fire/compat/database'
import { DbPathsProvider } from 'app/services/db-paths/db-paths.service'
import { offlineTimeout } from 'app/util'

@Component({
  selector: 'arm-disarm-button',
  templateUrl: './arm-disarm-button.component.html',
  styleUrls: ['./arm-disarm-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ArmDisarmButtonComponent {
  @select('currentDevice') currentDevice$: Observable<AppDevice>

  deviceName: string
  isArmed: boolean = false
  deviceOffline: boolean
  dbLastUpdated: number
  lastUpdated: number

  private ngUnsubscribe = new Subject<void>()

  constructor(
    private settingsProvider: SettingsProvider,
    private deviceProvider: DeviceProvider,
    private db: AngularFireDatabase,
    private paths: DbPathsProvider,
    private bluetoothProvider: BluetoothLEProvider,
    private environmentProvider: EnvironmentProvider,
    private helpers: HelpersProvider
  ) {
    this.db
      .object(
        `${this.paths.deviceSettings(
          this.deviceProvider.currentBRNKLandMateId$.value.deviceId
        )}/lastUpdated`
      )
      .valueChanges()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((val: number) => {
        this.dbLastUpdated = val
      })
  }

  ngOnInit(): void {
    this.currentDevice$
      .pipe(
        filter((device) => device != null),
        filter((device) => device.settings != null),
        filter((device) => device.settings.isArmed != null),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((device) => {
        this.deviceName = device.settings.deviceName
        this.isArmed = device.settings.isArmed
        this.lastUpdated = device.settings.lastUpdated

        // check if db has newer update (for refresh bug)
        if (this.dbLastUpdated > this.lastUpdated) {
          this.lastUpdated = this.dbLastUpdated
        }

        // check if lastUpdated timestamp (in seconds) was 3 or more hours ago
        this.deviceOffline = this.lastUpdated <= offlineTimeout
      })
  }

  async setArmed(): Promise<void> {
    const newIsArmedStatus = !this.isArmed

    try {
      if (
        this.environmentProvider.isNativeApp() &&
        this.deviceProvider.isMateLinked() &&
        this.bluetoothProvider.connected.value
      ) {
        // Notify Mate of Change
        await this.bluetoothProvider.setIsArmed(newIsArmedStatus)
        await this.settingsProvider.setIsArmed(newIsArmedStatus, true)
      } else {
        await this.settingsProvider.setIsArmed(newIsArmedStatus, false)
      }
    } catch (err) {
      this.helpers.showDangerToast(
        `Failed to update mate armed status. Please try again.`
      )
    }
  }

  offLineNotification() {
    let helpLink = 'help'
    helpLink.link('https://www.brnkl.io/help/')
    this.helpers.showInfiniteDangerToast(
      `${this.deviceName} is currently offline. \n\n For help reconnecting go to help.brnkl.io.`
    )
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }
}
