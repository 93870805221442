import { Injectable } from '@angular/core'
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  catchError,
  distinctUntilChanged,
  filter,
  forkJoin,
  from,
  map,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs'
import { DeviceProvider } from '../device/device.service'
import { AngularFireDatabase } from '@angular/fire/compat/database'
import { DbPathsProvider } from '../db-paths/db-paths.service'
import { CameraConfig } from 'app/models-shared/camera-config.model'
import { Camera } from 'app/models-shared/camera.model'
import { CloudFunctionsProvider } from '../cloud-functions/cloud-functions.service'
import { HlsService } from '../hls/hls.service'

@Injectable({
  providedIn: 'root',
})
export class VideosProvider {
  private selectedCameraId = new BehaviorSubject<string>(null)
  public videos$: Observable<any>
  private thumbnailCache = new Map<string, string>()

  constructor(
    private device: DeviceProvider,
    private db: AngularFireDatabase,
    private paths: DbPathsProvider,
    private cloudFunctions: CloudFunctionsProvider,
    private hlsService: HlsService
  ) {
    this.videos$ = this.selectedCameraId.pipe(
      distinctUntilChanged(),
      switchMap((camId) => {
        if (camId) {
          return this.getVideos(camId)
        } else {
          return of([])
        }
      })
    )
  }

  private changeBlobtoBase64 = async (imageBlob: Blob): Promise<string> => {
    const reader = new FileReader()
    return new Promise<string>((resolve, reject) => {
      reader.onloadend = (): void => resolve(<string>reader.result)
      reader.onerror = (): void => reject(new Error('Reader error'))
      reader.readAsDataURL(imageBlob)
    })
  }

  public getThumbnail(
    deviceId: string,
    camId: string,
    fileName: string
  ): Observable<string> {
    const thumbnailKey = this.getThumbnailCacheKey(deviceId, camId, fileName)
    if (this.thumbnailCache.has(thumbnailKey)) {
      return of(this.thumbnailCache.get(thumbnailKey))
    }
    const endpointURL = `thumbnail/${deviceId}?camId=${camId}&fileName=${fileName}`
    return from(this.cloudFunctions.authedGetImage(endpointURL)).pipe(
      switchMap((imageResponse) => this.changeBlobtoBase64(imageResponse)),
      catchError(() => EMPTY), // if there's an error, do nothing
      tap((base64Image) => {
        this.thumbnailCache.set(thumbnailKey, base64Image)
      })
    )
  }

  public getCameraThumbnail(
    deviceId: string,
    camId: string
  ): Observable<string> {
    const thumbnailKey = this.getThumbnailCacheKey(deviceId, camId, 'latest')
    if (this.thumbnailCache.has(thumbnailKey)) {
      return of(this.thumbnailCache.get(thumbnailKey))
    }
    const endpointURL = `cameraThumbnail/${deviceId}?camId=${camId}`
    return from(this.cloudFunctions.authedGetImage(endpointURL)).pipe(
      switchMap((imageResponse) => this.changeBlobtoBase64(imageResponse)),
      catchError(() => EMPTY), // if there's an error, do nothing
      tap((base64Image) => {
        this.thumbnailCache.set(thumbnailKey, base64Image)
      })
    )
  }

  public preloadThumbnails(
    deviceId: string,
    camId: string,
    videoList: any[]
  ): void {
    videoList.forEach(async (video) => {
      const thumbnailKey = this.getThumbnailCacheKey(
        deviceId,
        camId,
        video.fileName
      )
      if (!this.thumbnailCache.has(thumbnailKey)) {
        const endpointURL = `thumbnail/${deviceId}?camId=${camId}&fileName=${video.fileName}`
        const imageResponse: Blob = await this.cloudFunctions.authedGetImage(
          endpointURL
        )
        const base64Image: string = await this.changeBlobtoBase64(imageResponse)
        this.thumbnailCache.set(thumbnailKey, base64Image)
      }
    })
  }

  public getVideosForCamera(deviceId: string, camId: string) {
    return this.videos$.pipe(
      switchMap((videos) => {
        if (!videos) {
          return of([])
        }
        // Use forkJoin to wait until all thumbnail data has been fetched
        const videoObservables = videos.map((video) =>
          this.getVideoWithThumbnail(deviceId, camId, video)
        )
        return forkJoin(videoObservables)
      })
    )
  }

  public getVideoWithThumbnail(
    deviceId: string,
    camId: string,
    video: any
  ): Observable<any> {
    return from(this.getVideoUrl(deviceId, camId, video.fileName)).pipe(
      switchMap((url) => {
        const endpointURL = `thumbnail/${deviceId}?camId=${camId}&fileName=${video.fileName}`
        return from(this.cloudFunctions.authedGetImage(endpointURL)).pipe(
          switchMap((imageResponse) => this.changeBlobtoBase64(imageResponse)),
          map((base64Image) => {
            // Populate video data here to ensure thumbnail is ready
            return {
              ...video,
              url: url,
              thumbnail: base64Image,
            }
          })
        )
      })
    )
  }

  private getThumbnailCacheKey(
    deviceId: string,
    camId: string,
    fileName: string
  ): string {
    return `${deviceId}-${camId}-${fileName}`
  }

  public async getVideoUrl(
    deviceId: string,
    camId: string,
    filename: string
  ): Promise<string> {
    const endpointURL = `video/${deviceId}?camId=${camId}&fileName=${filename}`
    const videoBlob: string = await this.cloudFunctions.authedGetPromise(
      endpointURL
    )

    return videoBlob
  }

  public selectCamera(camId: string) {
    this.selectedCameraId.next(camId)
  }

  public getCameras(): Observable<any> {
    return this.device.currentBRNKLandMateId$.pipe(
      switchMap((ids) => {
        if (!ids.deviceId) return of([])
        return this.db
          .object<CameraConfig>(this.paths.cameraConfig(ids.deviceId))
          .valueChanges()
          .pipe(
            filter((cameraConfig) => cameraConfig != null),
            takeUntil(this.device.deviceUnselected$),
            map((cameraConfig) => {
              const camIds = Object.keys(cameraConfig)
              const configs: Array<{ camId: string; config: Camera }> = []
              for (const camId of camIds) {
                configs.push({
                  camId,
                  config: cameraConfig[camId],
                })
              }

              return configs
            })
          )
      })
    )
  }

  public loadStreams(): void {
    // Fetch the list of cameras
    this.getCameras()
      .pipe(
        take(1), // Take only the first emission
        switchMap((cameras) => {
          // For each camera, load the stream using webrtcService and convert the Promise to Observable
          const streamPromises = cameras.map((camera) =>
            this.hlsService.loadStream(camera.camId)
          )
          return from(Promise.all(streamPromises)) // Wait for all streams to be loaded
        })
      )
      .subscribe({
        next: () => {
          console.log('All streams loaded successfully.')
        },
        error: (err) => {
          console.error('Error loading streams:', err)
        },
      })
  }

  public getVideos(camId: string): Observable<any> {
    return this.device.currentBRNKLandMateId$.pipe(
      switchMap((ids) => {
        const path = this.paths.videosList(ids.deviceId, camId)
        return this.db
          .list(path, (ref) => ref.orderByChild('datetime').limitToFirst(1))
          .valueChanges()
          .pipe(take(1), takeUntil(this.device.deviceUnselected$))
      })
    )
  }
}
