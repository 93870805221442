<div class="stats-grid-open-btn" (click)="toggleOpen()">
  <div class="stats-grid-open-btn-icon">
    <ng-container *ngIf="!expanded; else closed">
      <ion-icon name="chevron-up-outline"></ion-icon>
    </ng-container>
    <ng-template #closed>
      <ion-icon name="chevron-down-outline"></ion-icon>
    </ng-template>
  </div>

  <div class="stats-grid_last-updated">
    <span class="stats-grid_last-updated-label"> Updated:&nbsp; </span>
    {{ (currentDevice$ | async)?.statsLastUpdated?.formatted || 'Unavailable' }}
  </div>
</div>

<ng-container *ngIf="currentDevice$ | async as currentDevice">
  <div
    class="stats-grid_container"
    [class.stats-grid_container--closed]="!expanded"
  >
    <ion-grid class="stats-grid_grid">
      <ion-row *ngIf="currentDevice?.stats?.length">
        <ion-col
          *ngFor="
            let stat of currentDevice?.stats;
            trackBy: getStatKey
          "
          size="6"
          size-md="3"
          size-lg="2"
        >
          <div class="stats-grid_tile-container">
            <div
              class="stats-grid_tile"
            >
              <div class="stats-grid_wireless-info" *ngIf="stat.isWireless">
                <div>
                  {{
                    stat?.temp !== null && stat?.temp !== undefined
                      ? stat.temp + ' °' + getWirelessSensorPreferredTempUnit()
                      : ''
                  }}
                </div>
                <div class="wireless-status-icons">
                  <ion-icon name="wifi"></ion-icon>
                  <ion-icon
                    class="wireless-battery-icon"
                    name="custom-battery-full"
                    *ngIf="stat?.batt > 80 || stat?.batt == 80"
                  ></ion-icon>
                  <ion-icon
                    class="wireless-battery-icon"
                    name="custom-battery-med"
                    *ngIf="stat?.batt > 20 && stat?.batt < 80"
                  ></ion-icon>
                  <ion-icon
                    class="wireless-battery-icon"
                    name="custom-battery-low"
                    *ngIf="stat?.batt < 20 || stat?.batt == 20"
                  ></ion-icon>
                </div>
              </div>
              <div class="stats-grid_info-container">
                <div class="stats-grid_label">
                  {{ stat?.name }}
                </div>
                <div class="stats-grid_value">
                  {{ stat?.val }}
                  <span
                    class="stats-grid_unit"
                    *ngIf="stat?.unit === 'C' || stat?.unit === 'F'"
                    >{{ ' °' }}</span
                  ><span
                    class="stats-grid_unit"
                    *ngIf="stat?.preferredUnit || stat?.unit"
                    >{{ stat?.unit | uppercase }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <div
    class="stats-grid_gps-container"
    *ngIf="expanded && !currentDevice?.gps?.dontTransform"
  >
    <span class="stats-grid_label"> GPS:&nbsp;&nbsp; </span>
    <span class="stats-grid_gps-value"
      >{{
        currentDevice?.gps?.latest?.lat
          | number: '1.1-6'
          | convertLatitudeToDegrees
            : currentDevice?.settings?.mapConfig?.gpsUnits
      }},&nbsp;&nbsp;</span
    >
    <span class="stats-grid_gps-value">{{
      currentDevice?.gps?.latest?.long
        | number: '1.1-6'
        | convertLongitudeToDegrees
          : currentDevice?.settings?.mapConfig?.gpsUnits
    }}</span>
  </div>
</ng-container>
