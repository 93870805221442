export enum SensorTypes {
  BilgeActivity = 0,
  Analog,
  Motion,
  Geofence,
  Temp,
  Humid,
  Digital,
  Pressure,
  Impact,
  PitchAngle,
  HeelAngle,
  GPS,
  WirelessMulti,
  WirelessWater,
  WirelessKeypad,
  WirelessKeyfob,
  WirelessMotion,
  WirelessTemp,
}

export const isSensorAverageable = (s: SensorTypes): boolean => {
  switch (s) {
    case SensorTypes.Analog:
    case SensorTypes.Temp:
    case SensorTypes.Humid:
    case SensorTypes.Pressure:
    case SensorTypes.Impact:
    case SensorTypes.PitchAngle:
    case SensorTypes.HeelAngle:
    case SensorTypes.WirelessTemp:
      return true
    default:
      return false
  }
}

export const isSensorNonAverageable = (s: SensorTypes): boolean => {
  switch (s) {
    case SensorTypes.Digital:
    case SensorTypes.Motion:
    case SensorTypes.BilgeActivity:
    case SensorTypes.WirelessMulti:
    case SensorTypes.WirelessWater:
    case SensorTypes.WirelessKeypad:
    case SensorTypes.WirelessKeyfob:
    case SensorTypes.WirelessMotion:
      return true
    default:
      return false
  }
}

export const isSensorWireless = (s: SensorTypes): boolean => {
  switch (s) {
    case SensorTypes.WirelessMulti:
    case SensorTypes.WirelessWater:
    case SensorTypes.WirelessKeypad:
    case SensorTypes.WirelessKeyfob:
    case SensorTypes.WirelessMotion:
    case SensorTypes.WirelessTemp:
      return true
    default:
      return false
  }
}

export const isSensorDigitalWireless = (s: SensorTypes): boolean => {
  switch (s) {
    case SensorTypes.WirelessMulti:
    case SensorTypes.WirelessWater:
    case SensorTypes.WirelessMotion:
      return true
    default:
      return false
  }
}

export const getWirelessSensorKeyPrefix = (s: SensorTypes): string => {
  switch (s) {
    case SensorTypes.WirelessMulti:
      return 'multiW'
    case SensorTypes.WirelessWater:
      return 'waterW'
    case SensorTypes.WirelessMotion:
      return 'motionW'
    case SensorTypes.WirelessKeypad:
      return 'keypadW'
    case SensorTypes.WirelessKeyfob:
      return 'keyfobW'
    case SensorTypes.WirelessTemp:
      return 'tempW'
    default:
      return undefined
  }
}
