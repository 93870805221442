<ion-header>
  <ion-toolbar class="toolbar-background">
    <!-- Map Nav Bar-->
    <ion-buttons class="tabbar-tile-start" slot="start">
      <arm-disarm-button></arm-disarm-button>
    </ion-buttons>
    <ion-buttons class="tabbar-col-center">
      <top-bar-title
        class="tabbar-title-overflow toolbar-title"
      ></top-bar-title>
    </ion-buttons>
    <ion-buttons class="tabbar-tile-end" slot="end">
      <ion-button
        class="toolbar-button"
        category="bar-button"
        (click)="requestPhoto()"
      >
        Take Photo
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content padding>
  <ion-grid *ngIf="(visiblePhotosWithUrl$ | async) as photos;  else noPhotos">
    <div *ngIf="photos.length; else noPhotos">
      <ion-row>
        <ion-col size="12" class="photos_header">
          <span class="photos_header_title">Last Photo</span>
          <span class="photos_header_date">{{ lastUpdated$ | async }}</span>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="6" size-md="4" *ngFor="let photo of photos">
          <div *ngIf="photo?.url$ | async" class="photo_img-container">
            <img
              *ngIf="!photo?.hidden"
              [src]="photo?.url$ | async"
              alt="Photo taken at {{photo?.date}}"
              class="photos_img rotate-{{photo?.rotation}}"
              (click)="openModal(photo)"
            />
          </div>
        </ion-col>
      </ion-row>
    </div>
  </ion-grid>
  <ng-template #noPhotos>
    <ion-list>
      <ion-item> No Photos </ion-item>
    </ion-list>
  </ng-template>
  <ion-infinite-scroll (ionInfinite)="scrollDown($event)">
    <ion-infinite-scroll-content></ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
