<ion-header>
  <ion-toolbar class="toolbar-background">
    <!-- Map Nav Bar-->
    <ion-buttons class="tabbar-tile-start" slot="start">
      <arm-disarm-button></arm-disarm-button>
    </ion-buttons>
    <ion-buttons class="tabbar-col-center">
      <top-bar-title
        class="tabbar-title-overflow toolbar-title"
      ></top-bar-title>
    </ion-buttons>
    <ion-buttons class="tabbar-tile-end" slot="end">
      <ion-button
        class="toolbar-button"
        icon-only
        (click)="presentPopover($event)"
      >
        <ion-icon class="toolbar-title" name="ellipsis-vertical"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content padding *ngIf="thumbnails$ | async as thumbnails">
  <ion-list>
    <ion-item *ngIf="!(visibleAlerts$ | async)?.length">No Alerts</ion-item>
    <ion-item
      *ngFor="let alert of visibleAlerts$ | async; trackBy: trackByAlertId"
      detail="false"
      button
      (click)="showDetails(alert, thumbnails[alert.alertId]?.url)"
    >
      <ion-icon
        *ngIf="!alert.video"
        slot="start"
        name="alert-circle"
      ></ion-icon>
      <div *ngIf="alert.video" class="thumbnail">
        <!-- Use ng-container to conditionally display spinner or thumbnail -->
        <ng-container
          *ngIf="thumbnails[alert.alertId]?.loading; else thumbnailTemplate"
        >
          <img [src]="thumbnails[alert.alertId]?.url" />
        </ng-container>
        <ng-template #thumbnailTemplate>
          <ion-spinner></ion-spinner>
          <img
            [src]="thumbnails[alert.alertId]?.url"
            (load)="thumbnails[alert.alertId].loading = false"
            display="none"
          />
        </ng-template>
      </div>
      <ion-label class="ion-text-wrap">
        <div class="alerts_title">{{ alert.title }}</div>
        <div class="alerts_date">{{ formatAlertDate(alert.datetime) }}</div>
      </ion-label>
      <ion-icon slot="end" name="chevron-forward"></ion-icon>
    </ion-item>
  </ion-list>
  <div>
    <ion-infinite-scroll (ionInfinite)="scrollDown($event)">
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </div>
</ion-content>
