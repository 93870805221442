import { NgRedux, select } from '@angular-redux/store'
import { Component, ViewEncapsulation } from '@angular/core'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'

import { ActionTypes } from '../../models/action-types.model'
import { GraphSlide } from '../../models/graph-slide.model'
import { State } from '../../models/state.model'
import { Option } from '../../models/option.model'

import { StatsProvider } from '../../services/stats/stats.service'
import { GraphInfo } from '../../models/graph-info.model'
import { buildGraphSlide } from './graph-builder'
import { EnvironmentProvider } from 'app/services/environment/environment.service'
import 'chartjs-adapter-moment'

import SwiperCore, { Pagination } from 'swiper'

// install Swiper modules
SwiperCore.use([Pagination])

@Component({
  selector: 'page-graphs',
  templateUrl: './graphs.page.html',
  styleUrls: ['./graphs.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GraphsPage {
  @select(['currentDevice', 'graphInfo'])
  graphInfo$: Observable<GraphInfo[]>
  @select(['currentDevice', 'isGraphLoading'])
  isGraphLoading$: Observable<boolean>
  nHoursOfData$: Observable<number>
  nHoursOfDataString$: Observable<String>
  isIOSDevice: boolean
  lineChartLegend: boolean = false
  nDateTicksHourly: number = 4
  nDateTicksWeekly: number = 7
  lineChartColors: {
    backgroundColor: '#4BC0C0'
    borderColor: '#3D9C9C'
  }
  chartType: string = 'line'
  dateRangeOptions: Option<number>[] = [
    {
      label: '3 hours',
      value: 3,
    },
    {
      label: '12 hours',
      value: 12,
    },
    {
      label: '1 day',
      value: 24,
    },
    {
      label: '7 days',
      value: 168,
    },
  ]
  statsExpanded: boolean = false
  graphLineTension: number = 0.1
  graphs: Observable<GraphSlide[]>
  constructor(
    private statsProvider: StatsProvider,
    private environmentProvider: EnvironmentProvider,
    private ngRedux: NgRedux<State>
  ) {
    this.isIOSDevice = this.environmentProvider.isIOSApp()
    this.graphs = this.graphInfo$.pipe(
      filter((infoList: GraphInfo[]) => infoList != null),
      map((infoList: GraphInfo[]) =>
        infoList.map((info: GraphInfo) => buildGraphSlide(info))
      )
    )

    this.nHoursOfDataString$ = this.statsProvider.nHoursOfGraphData$.pipe(
      map((num) => (num != null && !isNaN(num) ? num : 12)),
      map((num) => num.toString())
    )
  }

  toggleStatsExpanded() {
    this.statsExpanded = !this.statsExpanded
  }

  setNHoursData(nHours: number): void {
    if (nHours == null || isNaN(nHours)) {
      return
    }

    this.ngRedux.dispatch({
      type: ActionTypes.SET_CURRENT_DEVICE_GRAPH_LOADING,
      isGraphLoading: true,
    })
    this.statsProvider.setNHoursGraphData(+nHours)
  }

  refresh(): void {
    this.setNHoursData(+this.ngRedux.getState().currentDevice.nHoursGraphData)
  }

  ionViewWillEnter(): void {
    this.refresh()
  }
}
