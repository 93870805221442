<ion-header>
  <ion-toolbar class="toolbar-background">
    <!-- Map Nav Bar-->
    <ion-buttons class="tabbar-tile-start" slot="start">
      <ion-button
        class="toolbar-button"
        *ngIf="currentStep != 'selectCamera'"
        (click)="currentStep='selectCamera'"
      >
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
      <arm-disarm-button></arm-disarm-button>
    </ion-buttons>
    <ion-buttons class="tabbar-col-center">
      <top-bar-title
        class="tabbar-title-overflow toolbar-title"
      ></top-bar-title>
    </ion-buttons>
    <ion-buttons class="tabbar-tile-end" slot="end">
      <bluetooth-button></bluetooth-button>
      <!-- Must wrap signal-indicator in buttons to get correct position-->
      <ion-button fill="clear" class="top-bar_signal toolbar-button">
        <signal-indicator></signal-indicator>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content
  *ngIf="thumbnails$ | async as thumbnails"
  [scrollY]="currentStep !== 'displayWebRTC'"
  style="padding: 10px; margin: 0"
>
  <div
    [ngSwitch]="currentStep"
    [ngStyle]="{'padding-top': currentStep !== 'displayWebRTC' ? '10px' : '0'}"
    style="width: 100%; height: 100%"
  >
    <!-- Show a spinner if it is loading -->
    <div *ngSwitchCase="'selectCamera'">
      <ion-list *ngIf="cameras?.length > 0; else noCameraTemplate">
        <ion-item
          button
          *ngFor="let item of cameras"
          (click)="displayStream(item.camera.uuid)"
        >
          <div class="thumbnail-container">
            <ng-container *ngIf="item.thumbnail; else loadingSpinner">
              <img
                [src]="thumbnails[item.camera.uuid]"
                class="thumbnail rounded"
              />
            </ng-container>
            <ng-template #loadingSpinner>
              <ion-spinner></ion-spinner>
            </ng-template>
          </div>
          <ion-label>
            <h2 class="cam-id">{{ item.camera.camName }}</h2>
            <p2 class="camera-type">{{ item.camera.cameraType }}</p2>
          </ion-label>
          <div class="actions">
            <p class="timestamp">{{ item.camera.timestamp | date:'short' }}</p>
          </div>
        </ion-item>
      </ion-list>

      <ng-template #noCameraTemplate>
        <ion-list>
          <ion-item>No cameras available</ion-item>
        </ion-list>
      </ng-template>
    </div>

    <!-- Display WebRTC video in an iframe when a camera is selected -->
    <div *ngSwitchCase="'displayWebRTC'" style="width: 100%; height: 100%">
      <video-player
        [camId]="currentCamera.camera.uuid"
        [thumbnail]="thumbnails[currentCamera.camera.uuid]"
      ></video-player>
    </div>
  </div>
</ion-content>
