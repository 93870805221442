import 'firebase/storage'
import { Injectable } from '@angular/core'
import { AngularFireDatabase } from '@angular/fire/compat/database'
import { Observable, switchMap, combineLatest, of, timer } from 'rxjs'
import { filter, takeUntil, map } from 'rxjs/operators'
import { Photo } from '../../models/photo.model'
import { formatDate } from '../../reducers/root-reducer/datetime-reducer'
import { DbPathsProvider } from '../db-paths/db-paths.service'
import { DeviceProvider } from '../device/device.service'
import { FunctionsRes } from '../../models-shared/functions-res.model'
import { CloudFunctionsProvider } from '../cloud-functions/cloud-functions.service'
import { CameraConfig } from '../../models-shared/camera-config.model'
import { PhotoMetadata } from '../../models-shared/photo-metadata.model'
import { Camera } from '../../models-shared/camera.model'
import { BehaviorSubject } from 'rxjs'
import { unixNow, oneWeek } from '../../util'

@Injectable({
  providedIn: 'root',
})
export class PhotosProvider {
  private nInitialPhotos: number = 20
  private nPerScroll: number = 10
  private scrollDebounceSec: number = 2

  private nVisiblePhotos$ = new BehaviorSubject(this.nInitialPhotos)

  public photos$: Observable<Photo[]>

  private weeksToQuery$ = new BehaviorSubject(1)

  constructor(
    private db: AngularFireDatabase,
    private device: DeviceProvider,
    private paths: DbPathsProvider,
    private cloudFunctions: CloudFunctionsProvider
  ) {
    this.photos$ = this.getPhotos()
  }

  public getBRNKLdevicePhotos(deviceId: string): Observable<Photo[]> {
    // Get CameraConfigs from database
    const cameraConfigObservable = this.db
      .object<CameraConfig>(this.paths.cameraConfig(deviceId))
      .valueChanges()
      .pipe(
        filter((cameraConfig) => cameraConfig != null),
        takeUntil(this.device.deviceUnselected$),
        map((cameraConfig) => {
          const camIds = Object.keys(cameraConfig)
          const configs: Array<{ camId: string; config: Camera }> = []
          for (const camId of camIds) {
            configs.push({
              camId,
              config: cameraConfig[camId],
            })
          }

          return configs
        })
      )

    // Attach the last photo from database
    const cameraConfigLastDatetimeObservable = cameraConfigObservable.pipe(
      switchMap((configs) => {
        const observables = configs.map((config) => {
          return this.db
            .list<PhotoMetadata>(
              this.paths.photosList(deviceId, config.camId),
              (ref) => ref.orderByChild('datetime').limitToFirst(1)
            )
            .valueChanges()
            .pipe(
              map((photolist: PhotoMetadata[]) => {
                if (photolist && photolist.length == 1) {
                  return {
                    ...config,
                    lastDatetime: photolist[0].datetime,
                  }
                }
                return {
                  ...config,
                  lastDatetime: null,
                }
              }),
              takeUntil(this.device.deviceUnselected$)
            )
        })

        return combineLatest(...observables)
      })
    )

    // Get Photolists
    return (
      combineLatest(
        cameraConfigLastDatetimeObservable,
        this.nVisiblePhotos$,
        this.weeksToQuery$
      )
        .pipe(
          switchMap(([configs, nVisiblePhotos, weeksToQuery]) => {
            const startAtDatetime = unixNow() - oneWeek * weeksToQuery
            const observables = configs.map((config) => {
              return this.db
                .list<PhotoMetadata>(
                  this.paths.photosList(deviceId, config.camId),
                  (ref) =>
                    ref
                      .orderByChild('datetime')
                      .startAt(startAtDatetime)
                      .endAt(Number.MAX_SAFE_INTEGER)
                )
                .valueChanges()
                .pipe(
                  map((photolist: PhotoMetadata[]) => {
                    // Filter Hidden Photos
                    let processedPhotolist = photolist.filter(
                      (photo) => !photo.hidden
                    )

                    const diff = processedPhotolist.length - nVisiblePhotos
                    if (diff > 0) {
                      // Slice from the front as list is ascending
                      processedPhotolist = processedPhotolist.slice(diff)
                    }
                    return processedPhotolist
                  }),
                  map((photolist: PhotoMetadata[]) => {
                    return {
                      photolist,
                      ...config,
                    }
                  }),
                  takeUntil(this.device.deviceUnselected$)
                )
            })

            // Check if more photos need to be queried
            const allConfigsAndPhotos = combineLatest(...observables)

            // Only emit the observable if it does not need to be re-queried
            const validConfigsAndPhotos = allConfigsAndPhotos.pipe(
              filter((photosAndConfigs) => {
                // Determine oldest possible photo
                let lastDateTime = Number.MAX_SAFE_INTEGER
                let photolistCount = 0
                for (const photosAndConfig of photosAndConfigs) {
                  photolistCount += photosAndConfig.photolist.length
                  if (
                    photosAndConfig.lastDatetime &&
                    lastDateTime > photosAndConfig.lastDatetime
                  ) {
                    lastDateTime = photosAndConfig.lastDatetime
                  }
                }

                if (
                  photolistCount < nVisiblePhotos &&
                  lastDateTime &&
                  startAtDatetime > lastDateTime
                ) {
                  // Not enough photos increase weeks to query
                  this.weeksToQuery$.next(this.weeksToQuery$.value + 1)
                  return false
                }

                return true
              })
            )

            return validConfigsAndPhotos
          })
        )
        // Ensure Photolists exists
        .pipe(
          filter((photosAndConfigs) => photosAndConfigs.length > 0),
          // Build Photo Array
          map((photosAndConfigs): Photo[] => {
            const photos: Photo[] = []
            for (const photosAndConfig of photosAndConfigs) {
              const cameraConfig = photosAndConfig.config

              const camPhotos = photosAndConfig.photolist.map(
                (photo: PhotoMetadata): Photo => {
                  return {
                    deviceId,
                    camId: photosAndConfig.camId,
                    photoId: photo.dbKey,
                    datetime: photo.datetime,
                    filename: photo.filename,
                    hidden: !!photo.hidden,
                    date: formatDate(photo.datetime),
                    rotation: cameraConfig.rotation,
                  }
                }
              )

              photos.push(...camPhotos)
            }

            return photos
          }),
          filter((photos) => photos.length > 0)
        )
    )
  }

  // TODO: Re-add support for photos on the mate node.
  // Requires modification of this commented out code.
  // public getMatePhotos(mateId: string): Observable<Photo[]> {
  //   return (
  //     this.db
  //       .object(this.paths.mateCamera(mateId, 'cam1'))
  //       .valueChanges()
  //       .map((cameraConfig): CameraConfig => ({ mateId, cameraConfig }))
  //       .takeUntil(this.device.mateChanged$)
  //       // Get Photolist
  //       .switchMap(({ cameraConfig }) =>
  //         this.db
  //           .object(this.paths.matePhotosList(mateId, 'cam1'))
  //           .valueChanges()
  //           .map((metadataDict: PhotoMetadata) => ({
  //             metadataDict,
  //             cameraConfig
  //           }))
  //           .takeUntil(this.device.mateChanged$)
  //       )
  //       // Ensure Photolist exists
  //       .filter(({ metadataDict, cameraConfig }) => metadataDict != null)
  //       // Build Photo Object
  //       .map(({ metadataDict, cameraConfig }) => {
  //         const photoIds: string[] = Object.keys(metadataDict)
  //         return photoIds.map(
  //           (photoId: string): Photo => {
  //             const metadata: PhotoMetadata = metadataDict[photoId]
  //             return {
  //               mateId,
  //               camId: 'cam1',
  //               photoId,
  //               datetime: metadata.datetime,
  //               filename: metadata.filename,
  //               hidden: !!metadata.hidden,
  //               date: formatDate(metadata.datetime),
  //               rotation: cameraConfig.rotation
  //             }
  //           }
  //         )
  //       })
  //       .filter(photos => photos[0].mateId != null)
  //       .startWith(null)
  //   )
  // }

  public getPhotos(): Observable<Photo[]> {
    return this.device.currentBRNKLandMateId$.pipe(
      switchMap((ids) => {
        let mateObservable: Observable<Photo[]> = of([])
        let brnklObservable: Observable<Photo[]> = of([])
        // TODO Re-add support for photos on the mate node
        // if (ids.mateId) {
        //   mateObservable = this.getMatePhotos(ids.mateId)
        // }

        if (ids.deviceId) {
          brnklObservable = this.getBRNKLdevicePhotos(ids.deviceId)
        }
        return combineLatest(brnklObservable, mateObservable)
      }),
      filter((vals): any => vals != null),
      map((unsortedPhotos: [Photo[], Photo[]]): Photo[] => {
        let sortedPhotos: Photo[]
        let unsortedPhotosArray: Photo[] =
          unsortedPhotos[1] != null
            ? unsortedPhotos[0].concat(unsortedPhotos[1])
            : unsortedPhotos[0]
        // sort and reverse photos for newer photos
        sortedPhotos = unsortedPhotosArray.sort(
          (a: Photo, b: Photo) => b.datetime - a.datetime
        )
        return sortedPhotos
      })
    )
  }

  public hidePhoto(
    deviceId: string,
    mateId: string,
    camId: string,
    photoId: string
  ) {
    const path = deviceId
      ? `${this.paths.photosList(deviceId, camId)}/${photoId}`
      : `${this.paths.matePhotosList(mateId, camId)}/${photoId}`
    this.db.object(path).update({ hidden: true })
  }

  public async appendToVisiblePhotos(): Promise<void> {
    await timer(this.scrollDebounceSec * 1000).toPromise()
    const nVisiblePhotos = this.nVisiblePhotos$.value
    this.nVisiblePhotos$.next(nVisiblePhotos + this.nPerScroll)
  }

  public requestPhoto(
    deviceId: string = this.device.currentBRNKLandMateId$.getValue().deviceId
  ): Promise<FunctionsRes<void>> {
    return this.cloudFunctions.authedPut(`takephoto/${deviceId}`)
  }

  public reset() {
    this.nVisiblePhotos$.next(this.nInitialPhotos)
    this.weeksToQuery$.next(1)
  }
}
