import { Injectable } from '@angular/core'
import { AngularFireDatabase } from '@angular/fire/compat/database'
import { Platform } from '@ionic/angular'

import { GraphState } from '../../models/graph-state.model'
import { Coord } from '../../models/coord.model'
import { DeviceGps } from '../../models/device-gps.model'
import {
  SensorTypes,
  isSensorAverageable,
  isSensorWireless,
} from '../../models-shared/sensor-types'
import { Sensor } from '../../models-shared/sensor.model'
import { Stat } from '../../models-shared/stat.model'
import { DevicePack } from '../../models/device-pack.model'
import { MapConfig } from '../../models-shared/map-config.model'
import { DeviceSettings } from '../../models-shared/device-settings.model'

import { Observable, timer, merge, combineLatest, of, zip } from 'rxjs'
import {
  switchMap,
  filter,
  map,
  takeWhile,
  takeUntil,
  catchError,
  tap,
} from 'rxjs/operators'
import {
  convertFromSeconds,
  convertUnit,
  filterByMatchingDatetimes,
} from '../../util'
import * as convertValue from 'smartcar-unit'

import { CloudFunctionsProvider } from '../cloud-functions/cloud-functions.service'
import { SettingsProvider } from '../settings/settings.service'
import { DbPathsProvider } from '../db-paths/db-paths.service'
import { DeviceProvider } from '../device/device.service'
import { BluetoothLEProvider } from '../bluetooth-le/bluetooth-le.service'

import { BilgeSensor } from '../../models-shared/bilge-sensor.model'
import { DigitalSensor } from '../../models-shared/digital-sensor.model'
import { ThresholdSensor } from '../../models-shared/threshold-sensor.model'
import {
  AnalogSensor,
  AnalogSensorTypes,
} from '../../models-shared/analog-sensor.model'
import { WirelessDigitalSensor } from '../../models-shared/wireless-digital-sensor.model'
import { CurrentIds } from '../../models/ids.model'
import { StatSources } from '../../models-shared/stat-sources'
import { BehaviorSubject } from 'rxjs'
import { GraphQueryInfo } from '../../models/graph-query-info.model'
import { GraphInfo } from '../../models/graph-info.model'
import { UserSettings } from '../../models-shared/user-settings.model'
import { HttpParams } from '@angular/common/http'

const MAX_N_MAP_POINTS: number = 360
const TARGET_DENSITY: number = 50
const DEFAULT_N_DECIMALS: number = 2
const DEFAULT_WIRELESS_TEMP_DECIMALS = 0

export const CELL_STRENGTH_KEY: string = 'cellstrength'

export const transformDigital = (
  sensor: DigitalSensor,
  point: Stat<boolean>
): Stat<string> => {
  const highName: string = sensor.highName || 'On'
  const lowName: string = sensor.lowName || 'Off'
  return {
    ...point,
    val: point.val ? highName : lowName,
  }
}

export const transformAnalog = (
  sensor: AnalogSensor,
  point: Stat<string>
): Stat<any> => {
  if (sensor.analogType === AnalogSensorTypes.Ignition) {
    const ignitionStat: Stat<number> = {
      // Round engine hours to the nearet 0.1 H
      val: Math.round(sensor.engineHours * 10) / 10,
      unit: 'h',
      datetime: point.datetime,
      source: point.source,
      name: sensor.name ? sensor.name : 'Engine Hours',
    }
    return ignitionStat
  } else if (
    sensor.analogType !== undefined &&
    sensor.analogType !== AnalogSensorTypes.Battery
  ) {
    const highName: string = sensor.highName
    const lowName: string = sensor.lowName
    const offset: number = sensor.offset || 0

    const isHighValue = +point.val + offset >= sensor.triggerValue

    return {
      ...point,
      val: isHighValue ? highName : lowName,
      unit: '',
    }
  } else {
    if (sensor.unit === 's' && ['m', 'h'].includes(sensor.preferredUnit)) {
      return {
        ...point,
        val:
          Math.round(
            convertFromSeconds(sensor.preferredUnit.toLowerCase(), +point.val) *
              10
          ) / 10,
      }
    }
    return point
  }
}

export const transformWirelessTemperature = (
  sensor: WirelessDigitalSensor,
  point: Stat<boolean>,
  wirelessPreferredUnit: string
): number => {
  let fixedTemp: number
  if (point.temp && point.isWireless) {
    // convert temp to user preferred unit
    fixedTemp =
      sensor.tempConfig.unit !== wirelessPreferredUnit
        ? setTemperature(sensor, point.temp, wirelessPreferredUnit)
        : point.temp
    // fix temperature to 0 decimal places
    fixedTemp = parseInt(fixedTemp.toFixed(DEFAULT_WIRELESS_TEMP_DECIMALS))
  }
  return fixedTemp
}

export const setTemperature = (
  sensor: WirelessDigitalSensor,
  val: number,
  unit: string
) => {
  const fromUnit = sensor.tempConfig.unit
  const convert = (val: number): number =>
    convertValue(val, fromUnit.toLowerCase()).to(unit.toLowerCase())
  return fromUnit ? convert(val) : val
}

export const setPreferredUnit = (sensor: ThresholdSensor, val: number) =>
  sensor.preferredUnit
    ? convertUnit(+val, sensor.unit.toLowerCase()).to(
        sensor.preferredUnit.toLowerCase()
      )
    : +val

export const applyOffset = (sensor: ThresholdSensor, point: number): number =>
  +point + (+sensor.offset || 0)

export const roundValue = (sensor: ThresholdSensor, point: number): number =>
  sensor.nDecimals >= 0
    ? Math.round(point * Math.pow(10, sensor.nDecimals)) /
      Math.pow(10, sensor.nDecimals)
    : point

// removes the posibility for -0
export const fixZero = (point: number): number =>
  point == 0 // -0 is considered equal to 0
    ? Math.abs(point)
    : point

export const makeNoPoint = (
  datetime: number = Date.now() / 1000
): Stat<string> => ({
  val: 'N/A',
  datetime,
  dontTransform: true,
  isWireless: false,
})

export const mapFilter = (
  k: Stat<any>[],
  mapConfig: MapConfig
): Stat<any>[] => {
  const inRangePoints: Stat<any>[] = mapConfig.lastGpsResetDatetime
    ? k.filter((s: Stat<any>) => s.datetime >= mapConfig.lastGpsResetDatetime)
    : k
  return k.length
    ? inRangePoints.length
      ? inRangePoints
      : [k[0]]
    : [makeNoPoint()]
}

@Injectable({
  providedIn: 'root',
})
export class StatsProvider {
  private switch$: Observable<CurrentIds>
  public switchedStats$: Observable<Stat<any>[]>
  public currentStats$: Observable<Stat<any>[]>
  public switchedGps$: Observable<DeviceGps>
  public switchedGraphData$: Observable<GraphState>
  public nHoursOfGraphData$: BehaviorSubject<number>
  public switchedCellStrength$: Observable<number>
  public userSettings$: BehaviorSubject<UserSettings>
  constructor(
    private db: AngularFireDatabase,
    private platform: Platform,
    private device: DeviceProvider,
    private paths: DbPathsProvider,
    private cloudFunctions: CloudFunctionsProvider,
    private settingsProvider: SettingsProvider,
    private bluetoothLEProvider: BluetoothLEProvider
  ) {
    this.nHoursOfGraphData$ = new BehaviorSubject(12)
    this.switch$ = this.getSwitch()
    this.switchedStats$ = this.getSwitchedStats() //this data is sent to the redux store in side effects
    this.switchedGps$ = this.getSwitchedGps()
    this.switchedGraphData$ = this.getSwitchedGraphData()
    this.switchedCellStrength$ = this.getSwitchedCellStrength()
    this.userSettings$ = this.settingsProvider.userSettings$
  }

  // TODO in the future we'll want to apply the offset
  // before conversion, since offset should be stored
  // in the "standard" format for a given sensor type
  private transformNumeric(
    sensor: ThresholdSensor,
    point: Stat<number>
  ): Stat<string> {
    const val: number = setPreferredUnit(sensor, point.val)
    const unit: string = sensor.preferredUnit || sensor.unit
    const offsetPoint: number = applyOffset(sensor, val)
    const valRounded: number = roundValue(sensor, offsetPoint)
    const valAbs: number = fixZero(valRounded)
    const valString: string = valAbs.toFixed(
      sensor.nDecimals !== undefined ? sensor.nDecimals : DEFAULT_N_DECIMALS
    )
    return {
      ...point,
      val: valString,
      unit,
    }
  }

  private transformValue(sensor: Sensor, point: Stat<any>): Stat<any> {
    if (!point.dontTransform) {
      if (isSensorAverageable(sensor.type)) {
        let stat = this.transformNumeric(sensor as ThresholdSensor, point)

        // Use transformed numeric stat to do high/low name comparison
        if (sensor.type == SensorTypes.Analog) {
          stat = transformAnalog(sensor as AnalogSensor, stat)
        }

        return stat
      } else if (
        sensor.type === SensorTypes.Digital ||
        sensor.type === SensorTypes.BilgeActivity ||
        sensor.type === SensorTypes.Motion
      ) {
        return transformDigital(sensor as DigitalSensor, point)
      } else if (
        sensor.type === SensorTypes.WirelessWater ||
        sensor.type === SensorTypes.WirelessMulti ||
        sensor.type === SensorTypes.WirelessMotion
      ) {
        const stat = transformDigital(sensor as DigitalSensor, point)
        stat.temp = transformWirelessTemperature(
          sensor as WirelessDigitalSensor,
          point,
          this.getWirelessSensorPreferredTempUnit()
        )
        return stat
      }
    }
    return point
  }

  private transformGraphValue(sensor: Sensor, point: Stat<any>): Stat<any> {
    if (!point.dontTransform) {
      if (isSensorAverageable(sensor.type)) {
        return this.transformNumeric(sensor as ThresholdSensor, point)
      }
    }
    return point
  }

  private getWirelessSensorPreferredTempUnit = () => {
    return this.userSettings$.value.preferredTempUnit
  }

  /**
   * Signal for the starting switchMap
   * which emits immediately, when the device resumes,
   * or when the deviceId$ emits
   */
  private getSwitch(): Observable<CurrentIds> {
    return this.device.currentBRNKLandMateId$.pipe(
      switchMap((ids: CurrentIds): Observable<CurrentIds> => {
        return merge(timer(0), this.platform.resume).pipe(
          map((): CurrentIds => ids),
          takeWhile(() => ids.deviceId != null)
        )
      })
    )
  }

  // depending on whether the mate is currently connected and via bluetooth and to the users
  // account, either grab stats from the mate or grab stats from the database
  private getSwitchedStats(): Observable<Stat<any>[]> {
    return this.switch$.pipe<Stat<any>[]>(
      switchMap((ids) => {
        const sensorConfig: Observable<Sensor[]> = this.getSensorConfig(ids)

        return sensorConfig.pipe(
          switchMap((sensors: Sensor[]) => {
            return this.getStatsFromSensors(sensors)
          })
        )
      })
    )
  }

  /**
   * Get the sensor config for a user given both the device and mate ids
   */
  private getSensorConfig(ids: CurrentIds): Observable<Sensor[]> {
    if (!ids.mateId) {
      return this.getDeviceSensorConfig(ids.deviceId)
    } else {
      return combineLatest([
        this.getDeviceSensorConfig(ids.deviceId),
        this.getMateSensorConfig(ids.mateId),
      ]).pipe(
        map(([deviceSensors, mateSensors]) => [
          ...deviceSensors,
          ...mateSensors,
        ])
      )
    }
  }

  /**
   * Get the sensorConfig object for deviceId
   */
  private getDeviceSensorConfig(deviceId: string): Observable<Sensor[]> {
    return this.db
      .list(this.paths.sensorConfig(deviceId))
      .valueChanges()
      .pipe(
        takeUntil(this.device.deviceUnselected$),
        map((sensors: Sensor[]): Sensor[] =>
          sensors.map((sensor) => ({
            ...sensor,
            id: deviceId,
          }))
        )
      )
  }

  /**
   * Get the sensorConfig object for mateId
   */
  private getMateSensorConfig(mateId: string): Observable<Sensor[]> {
    return this.db
      .list(this.paths.mateSensorConfig(mateId))
      .valueChanges()
      .pipe(
        takeUntil(this.device.mateChanged$),
        map((sensors: Sensor[]): Sensor[] =>
          sensors.map((sensor) => ({
            ...sensor,
            id: mateId,
          }))
        )
      )
  }

  /**
   * Get a stream of the latest stats from the db
   * for each sensor
   *
   */
  private getStatsFromSensors(sensors: Sensor[]): Observable<Stat<any>[]> {
    const stats$: Observable<Stat<any>>[] = sensors
      .filter((sensor: Sensor) => sensor.showOnGrid)
      .filter(
        (sensor: Sensor) =>
          sensor.type != SensorTypes.WirelessKeyfob &&
          sensor.type != SensorTypes.WirelessKeypad
      )
      .map(
        (
          sensor:
            | Sensor
            | ThresholdSensor
            | AnalogSensor
            | DigitalSensor
            | WirelessDigitalSensor
        ): Observable<Stat<any>> => {
          const isWirelessSensor = isSensorWireless(sensor.type)
          const path = isWirelessSensor
            ? this.paths.mateLatestSensorStatus(sensor.id, sensor.key)
            : this.paths.latestSensorStatus(sensor.id, sensor.key)

          const endingObservable = isWirelessSensor
            ? this.device.mateChanged$
            : this.device.deviceUnselected$
          const databaseObservable = this.db
            .object(path)
            .valueChanges()
            .pipe(
              takeUntil(endingObservable),
              // Handle when mate is removed (permissions revoked)
              catchError((err) => of(null)),
              map((stat: Stat<any>) => {
                return stat && stat.val != null ? stat : makeNoPoint()
              })
            )

          let resultObservable = databaseObservable
          if (isWirelessSensor) {
            const wirelessSensor = sensor as WirelessDigitalSensor
            const macAddress =
              wirelessSensor.wirelessConfig.macAddress.toUpperCase()
            const bleObseravble = this.bluetoothLEProvider.sensorData.pipe(
              map((bluetoothData) => bluetoothData[macAddress])
            )

            resultObservable = combineLatest([
              databaseObservable,
              this.bluetoothLEProvider.connected,
              bleObseravble,
            ]).pipe(
              map(([stat, connected, bleStat]): Stat<any> => {
                // Overwrite database stat if bluetooth data is available
                if (connected && bleStat != null) {
                  return bleStat
                }

                return stat
              })
            )
          }

          return resultObservable.pipe(
            map((stat): Stat<any> => {
              const s: ThresholdSensor = <ThresholdSensor>sensor
              return {
                ...stat,
                name: s.name,
                unit: s.unit,
                isWireless: isWirelessSensor,
              }
            }),
            map(
              (stat: Stat<any>): Stat<any> => this.transformValue(sensor, stat)
            ),
            tap((stat: Stat<any>) => {
              stat.key = sensor.key
            })
          )
        }
      )

    // Zip these into a single observable that emits
    // them as a list
    // THIS IS DEPRECATED AND MIGHT NOT WORK
    return combineLatest([...stats$], (...stats: Stat<any>[]) =>
      stats.filter((stat) => stat != null)
    )
  }

  private getSingleGpsObservable(
    pack: DevicePack<MapConfig>,
    subpath: string
  ): Observable<Stat<number>[]> {
    const { deviceId, val } = pack
    // Note: ordering by datetime puts oldest points first,
    // so we reverse the list each time it emits something
    return this.db
      .list(this.paths.sensorStatList(deviceId, subpath), (ref) => {
        const refOrderByChild = ref.orderByChild('datetime')
        const refLimitToLast = val
          ? refOrderByChild.limitToLast(val.maxGpsPoints || MAX_N_MAP_POINTS)
          : refOrderByChild.limitToLast(MAX_N_MAP_POINTS)
        return refLimitToLast
      })
      .valueChanges()
      .pipe(
        takeUntil(this.device.deviceUnselected$),
        // it's important to report at least one point even if the user
        // has "filtered" all points. For this reason, we filter after
        // querying the database and return the latest point if
        // the filter result is empty
        map((k: Stat<number>[]) => k.reverse()), // we want the newest point to be first in the list
        map((k) => (val ? mapFilter(k, val) : k))
      )
  }

  private coordSource(lat: Stat<number>, long: Stat<number>): number {
    if (!lat.source || !long.source) {
      return StatSources.Cellular
    }

    if (
      lat.source == StatSources.Offline &&
      long.source == StatSources.Offline
    ) {
      return StatSources.Offline
    }

    if (
      lat.source == StatSources.Satellite &&
      long.source == StatSources.Satellite
    ) {
      return StatSources.Satellite
    }

    return StatSources.Cellular
  }

  private getGpsList(pack: DevicePack<MapConfig>): Observable<DeviceGps> {
    return zip(
      this.getSingleGpsObservable(pack, 'lat'),
      this.getSingleGpsObservable(pack, 'long'),
      (latArr: Stat<number>[], longArr: Stat<number>[]) => {
        const [filteredLat, filteredLong] = filterByMatchingDatetimes(
          latArr,
          longArr
        )
        latArr = filteredLat
        longArr = filteredLong

        const coords: Coord[] = latArr.map((lat: Stat<number>, i: number) => {
          return {
            lat: lat.val,
            long: longArr[i].val,
            source: this.coordSource(lat, longArr[i]),
          }
        })
        // This is used to prevent the map
        // from rendering if the latest points
        // are non-transformable (i.e not real points (N/A))
        const dontTransform: boolean =
          !latArr[0] || !longArr[0]
            ? true
            : latArr[0].dontTransform || longArr[0].dontTransform
        return {
          dontTransform,
          history: coords,
          latest: coords[0],
        }
      }
    )
  }

  private getGraphDataObservable(
    deviceId: string,
    nHours: number
  ): Observable<{ [name: string]: Stat<any>[] }> {
    const hours = nHours != null && !isNaN(nHours) ? nHours : 12
    const params: HttpParams = new HttpParams()
      .set('npoints', TARGET_DENSITY)
      .set('nhours', hours)

    return this.cloudFunctions
      .authedGet<{ [name: string]: Stat<any>[] }>(`status/${deviceId}`, params)
      .pipe(takeUntil(this.device.deviceUnselected$))
  }

  private getGraphSwitch(): Observable<CurrentIds> {
    return this.device.currentBRNKLandMateId$.pipe(
      filter((ids) => ids.deviceId != null)
    )
  }

  private getGraphQueryInfo(): Observable<GraphQueryInfo> {
    return this.getGraphSwitch().pipe(
      switchMap((ids: CurrentIds) =>
        // COULD BE DEPRECATED AND NOT WORK
        combineLatest(
          [this.getSensorConfig(ids), this.nHoursOfGraphData$],
          (sensors: Sensor[], nHours: number): GraphQueryInfo => ({
            ids,
            nHours,
            sensors,
          })
        )
      )
    )
  }

  private prepareGraphData(
    statLists: { [name: string]: Stat<any>[] },
    sensors: Sensor[]
  ): GraphInfo[] {
    return sensors.map(
      (
        sensor: ThresholdSensor & BilgeSensor & DigitalSensor & AnalogSensor,
        index: number
      ) => {
        const data: Stat<any>[] = statLists[sensor.key]
        let transformedData = data.map((stat: Stat<any>) =>
          this.transformGraphValue(sensor, stat)
        )

        let unit = sensor.preferredUnit || sensor.unit || null

        if (sensor.type === SensorTypes.Analog) {
          const analogSensor: AnalogSensor = <any>sensor
          if (analogSensor.analogType != AnalogSensorTypes.Battery) {
            unit = null
            transformedData = data
          }
        }

        const graphInfo: GraphInfo = {
          stats: transformedData,
          name: sensor.name,
          unit: unit,
          steppedLine: false,
        }
        if (sensor.steppedLine) {
          graphInfo.steppedLine = true
        }
        if (!!sensor.highName && !!sensor.lowName) {
          graphInfo.highName = sensor.highName
          graphInfo.lowName = sensor.lowName
        }

        return graphInfo
      }
    )
  }

  private getSwitchedGraphData(): Observable<GraphState> {
    return this.getGraphQueryInfo().pipe(
      switchMap(({ ids, nHours, sensors }) =>
        this.getGraphDataObservable(ids.deviceId, nHours).pipe(
          map((statLists: { [name: string]: Stat<any>[] }) => {
            const graphSensors: Sensor[] = sensors.filter(
              (sensor: ThresholdSensor & BilgeSensor & DigitalSensor) =>
                sensor.showOnGrid && statLists[sensor.key]
            )

            const graphInfoList: GraphInfo[] = this.prepareGraphData(
              statLists,
              graphSensors
            )

            return {
              graphInfo: graphInfoList,
              nHoursGraphData: nHours,
            }
          })
        )
      )
    )
  }

  public setNHoursGraphData(nHours: number): void {
    this.nHoursOfGraphData$.next(+nHours)
  }

  private getSwitchedGps(): Observable<DeviceGps> {
    return this.switch$.pipe(
      switchMap((ids: CurrentIds) =>
        this.settingsProvider.deviceSettings$.pipe(
          filter((settings: DeviceSettings) => settings != null),
          map((settings: DeviceSettings) => ({
            val: settings.mapConfig,
            deviceId: ids.deviceId,
          }))
        )
      ),
      switchMap((pack: DevicePack<MapConfig>) => this.getGpsList(pack))
    )
  }

  private getSwitchedCellStrength(): Observable<number> {
    return this.switch$.pipe(
      switchMap((ids: CurrentIds) => {
        return this.db
          .list<Stat<any>>(
            this.paths.sensorStatList(ids.deviceId, CELL_STRENGTH_KEY),
            (ref) => ref.orderByChild('datetime').limitToLast(1)
          )
          .valueChanges()
          .pipe(takeUntil(this.device.deviceUnselected$))
      }),
      filter((stats: Stat<any>[]) => stats != null),
      filter((stats: Stat<any>[]) => !!stats[0]),
      map((stats): number => stats[0].val)
    )
  }
}
