<ion-header>
  <ion-toolbar hideBackButton="true" class="toolbar-background">
    <ion-title class="toolbar-title">View photo</ion-title>
    <ion-buttons slot="secondary">
      <ion-button class="toolbar-button" (click)="dismiss()">Close</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content padding>
  <div class="photo-modal">
    <div class="photo-modal_header">
      <span class="photo-modal_header-title">Taken at:</span>
      <span>{{ params.get('date') }}</span>
    </div>
    <div class="photo-modal_img-container">
      <img
        [src]="params.get('url$') | async"
        class="photo-modal_image rotate-{{ params.get('rotation') }}"
      />
    </div>
    <ion-button
      class="photo-modal_hide-button"
      block
      color="danger"
      (click)="hidePhoto()"
    >
      Hide Photo
    </ion-button>
  </div>
</ion-content>
