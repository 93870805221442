import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { HlsService } from 'app/services/hls/hls.service'
import Hls from 'hls.js'

@Component({
  selector: 'video-player',
  templateUrl: './video.html',
  styleUrls: ['./video.scss'],
})
export class VideoComponent implements OnInit, OnDestroy {
  @Input() public camId: string
  @Input() public thumbnail: string
  @Input() public videoSrc: string
  @ViewChild('video', { static: false }) videoElement: ElementRef

  loading: boolean = true
  client: string = ''
  showStillWatchingPrompt: boolean = false

  private hls: Hls
  private inactivityTimeout: any
  private inactivityDuration: number = 1 * 60 * 1000 // 5 minutes

  constructor(private hslService: HlsService) {}

  onVideoLoaded() {
    this.loading = false
    this.startInactivityTimer()
  }

  onVideoError() {
    // Handle error event here, possibly with a retry mechanism
    const video = this.videoElement.nativeElement
    this.retryConnection(video, this.client)
  }

  retryConnection(video, src) {
    setTimeout(() => {
      this.create(video, src)
    }, 1000)
  }

  create = (video, src) => {
    console.log('creating video', video, src)
    // always prefer hls.js over native HLS.
    // this is because some Android versions support native HLS
    // but don't support fMP4s.
    if (Hls.isSupported()) {
      this.hls = new Hls({
        maxLiveSyncPlaybackRate: 1.5,
      })

      this.hls.on(Hls.Events.ERROR, (evt, data) => {
        if (data.fatal) {
          this.hls.destroy()
        }
      })

      this.hls.loadSource(`${src}index.m3u8`)
      this.hls.attachMedia(video)

      video.play()
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      fetch(`${src}index.m3u8`).then((res) => {
        console.log('loaded', res)
        //this.loading = false
        video.src = `${src}stream.m3u8`
        video.play()
      })
    }
  }

  ngOnInit() {
    this.hslService.getStream(this.camId).then((client) => {
      const video = this.videoElement.nativeElement
      this.create(video, client)
    })
  }

  ngOnDestroy() {
    console.log('destroying video')
    if (this.hls) {
      this.hls.destroy() // destroy the Hls instance
    }
    const video = this.videoElement.nativeElement
    if (video) {
      console.log('clearing src')
      video.pause() // stop video playback
      video.src = '' // release the media source
      // If there are any attached Media Source Extensions, remove them
      if (video.srcObject) {
        ;(video.srcObject as MediaStream)
          .getTracks()
          .forEach((track) => track.stop())
        video.srcObject = null
      }
    }
    clearTimeout(this.inactivityTimeout)
  }

  startInactivityTimer() {
    const video = this.videoElement.nativeElement

    // Clear any existing inactivity timeout
    clearTimeout(this.inactivityTimeout)

    // Set a new inactivity timeout
    this.inactivityTimeout = setTimeout(() => {
      this.pauseAndPromptUser(video)
    }, this.inactivityDuration)
  }

  pauseAndPromptUser(video) {
    clearTimeout(this.inactivityTimeout)
    video.pause()
    this.hls.stopLoad()
    this.showStillWatchingPrompt = true
  }

  continueWatching(video) {
    this.showStillWatchingPrompt = false
    this.hls.startLoad()
    video.play()
    this.startInactivityTimer()
  }
}
