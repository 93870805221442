<ion-content class="add-wireless-sensor-content" [ngSwitch]="currentStep.name">
  <setup-container
    [title]="currentStep.title"
    [iconName]="currentStep.iconName"
    [backButton]="currentStep.backButton"
    [exitButton]="currentStep.exitButton"
    (back)="onBackPressed()"
    (exit)="onExitPressed()"
  >
    <setup-info
      *ngSwitchCase="'connect'"
      (next)="currentStep.next()"
      header="Connect Wiring"
      description="Connect the Mate wiring before you continue."
      nextButtonText="Next"
      [device]="mateInfo"
      topIcon="custom-wired-connection"
    ></setup-info>
    <setup-info
      *ngSwitchCase="'scanMate'"
      (next)="currentStep.next()"
      header="Scan"
      description="Scan the QR code on the Mate or on its packaging."
      nextButtonText="Scan"
      [device]="mateInfo"
      topIcon="custom-scan-code"
    ></setup-info>
    <setup-progress
      *ngSwitchCase="'find'"
      (next)="currentStep.next()"
      [description]="progressDescription"
      [progressStep]="progressStep"
      [progressPercent]="progressPercent"
      [doneButtonText]="progressDoneButton"
      (cancel)="onProgressCancel()"
      [device]="mateInfo"
      [progressIconName]="progressIconName"
      [header]="progressHeader"
    ></setup-progress>
  </setup-container>
</ion-content>
