import { Component } from '@angular/core'
import { SplashScreen } from '@ionic-native/splash-screen/ngx'
import {
  ModalController,
  NavController,
  NavParams,
  Platform,
} from '@ionic/angular'
import { StatusBar } from '@ionic-native/status-bar/ngx'
import { select } from '@angular-redux/store'
import { combineLatest, debounceTime, delay, Observable } from 'rxjs'
import { AppDevice } from './models/app-device.model'
import firebase from 'firebase/compat/app'
import { SideEffectsProvider } from './services/side-effects/side-effects.service'
import { EnvironmentProvider } from './services/environment/environment.service'
import { AppUpdateProvider } from './services/app-update/app-update.service'
import { DeviceProvider } from './services/device/device.service'
import { PageId, resolvePage } from './pages/layout/page-resolver'
import { AddServiceModal } from './components/add-service-modal/add-service-modal.component'
import { HelpersProvider } from './services/helpers/helpers.service'
import { BluetoothLEProvider } from './services/bluetooth-le/bluetooth-le.service'
import { NavDataService } from './services/navigation/navigation.service'
import { Router } from '@angular/router'
import { AngularFireDatabase } from '@angular/fire/compat/database'
declare var cordova: any

interface Route {
  method: string
  deviceId: string
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @select('allDevices') allDevices$: Observable<AppDevice[]>
  @select('currentDevice') currentDevice$: Observable<AppDevice>
  @select('currentUser') currentUser$: Observable<firebase.User>

  isMobileDevice: boolean
  currentPageId: PageId = null
  selectedDeviceId: string
  urlRoute: Route = null
  urlParams: { deviceId?: string } = {}
  urlData: { data: any } = null
  private refreshSettings: () => void

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private viewCtrl: ModalController,
    private helpers: HelpersProvider,
    private device: DeviceProvider,
    private navParams: NavParams,
    private navCtrl: NavController,
    private bluetoothProvider: BluetoothLEProvider,
    private sideEffects: SideEffectsProvider,
    private appUpdateProvider: AppUpdateProvider,
    private splashScreen: SplashScreen,
    private environment: EnvironmentProvider,
    private navData: NavDataService,
    private router: Router,
    private db: AngularFireDatabase
  ) {
    this.refreshSettings = this.navParams.get('refreshSettings')

    this.setupPlatform()
    this.setupDeviceReadyEvent()
    this.sideEffects.subscribe()

    this.isMobileDevice = this.environment.isNativeApp()
  }

  private setupPlatform(): void {
    this.platform.ready().then(() => {
      if (this.platform.is('android')) {
        this.statusBar.backgroundColorByHexString('#fff')

        const permissions = cordova.plugins.permissions

        const error = (permissionName: string) => {
          console.warn(`${permissionName} permission is not turned on`)
        }

        const grantPermission = (permissionName: string) => {
          permissions.checkPermission(
            permissionName,
            (status) => {
              if (!status.hasPermission) {
                permissions.requestPermission(
                  permissionName,
                  (status) => {
                    if (!status.hasPermission) error(permissionName)
                  },
                  () => error(permissionName)
                )
              }
            },
            null
          )
        }

        // Check and request POST_NOTIFICATIONS permission
        try {
          grantPermission('android.permission.POST_NOTIFICATIONS')
        } catch (e) {
          console.log(`Error requesting permissions: ${e}`)
        }
      }

      this.platform.backButton.subscribe(() => {})

      if (this.platform.is('android') || this.platform.is('ios')) {
        this.statusBar.styleDefault()
        this.statusBar.show()
      }

      this.splashScreen.hide()
    })
  }

  private setupDeviceReadyEvent(): void {
    document.addEventListener(
      'deviceready',
      () => {
        document.addEventListener(
          'resume',
          () => {
            this.onAppResume()
          },
          false
        )
      },
      false
    )
  }

  private onAppResume(): void {
    // The app has come to the foreground. Reconnect the database.
    this.db.database.goOffline()
    this.db.database.goOnline()
  }

  async ngOnInit() {
    // Get route params from ActivatedRoute
    // get data from url and window state
    this.urlRoute = getRoute()
    if (this.urlRoute && this.urlRoute.deviceId) {
      this.navData.set('deviceId', this.urlRoute.deviceId)
    }
    this.urlData = window.history.state

    // Navigate to the 'loading' page
    await this.router.navigate(['loading'])

    this.subscribeToLayoutState()
  }

  exit(): void {
    this.viewCtrl.dismiss()
  }

  openAddServiceModal = () => {
    this.helpers.showModal(
      AddServiceModal,
      {
        refreshSettings: this.refreshSettings,
        serviceType: 'satellite',
        newDeviceId: this.urlRoute.deviceId,
      },
      false,
      false,
      'setup-modal-container'
    )
  }

  isMateLinked() {
    return this.device.isMateLinked()
  }

  async connectToBluetooth() {
    if (this.isMateLinked() && this.isMobileDevice) {
      const mateId = this.device.currentBRNKLandMateId$.value.mateId
      if (!this.bluetoothProvider.connected.value) {
        await this.bluetoothProvider.connectToMate(mateId)
      }
    }
  }

  subscribeToLayoutState() {
    combineLatest([
      this.allDevices$,
      this.currentUser$,
      this.currentDevice$,
      this.device.currentBRNKLandMateId$,
      this.appUpdateProvider.mustUpdate$,
    ])
      .pipe(debounceTime(750))
      .subscribe(async (vals) => {
        const [allDevices, user, device, ids, mustUpdate] = vals

        const isAddDeviceRoute =
          this.urlRoute && this.urlRoute.method == 'add-device'

        const isDeeplinkRoute =
          this.urlRoute && this.urlRoute.method == 'app-deeplink'

        const isAddServiceRoute =
          this.urlRoute && this.urlRoute.method == 'add-satellite-service'

        const pageId = resolvePage(
          allDevices,
          user,
          device,
          ids.deviceId,
          isAddDeviceRoute,
          isDeeplinkRoute,
          isAddServiceRoute,
          mustUpdate
        )

        if (this.currentPageId === pageId) {
          return
        }

        // redirect through deeplink to mobile app
        if (pageId === PageId.Deeplink) {
          window.location.href = 'brnklapp://app'
        }

        if (pageId === PageId.AddService) {
          await this.setRoot(PageId.SelectDevice).then(() => {
            delay(2000)
            this.openAddServiceModal()
          })
        }

        if (isAddDeviceRoute && pageId === PageId.AddDevice) {
          const nDevices = allDevices ? allDevices.length : -1
          await this.handleAddDeviceRoute(ids.deviceId, nDevices)
        } else if (pageId === PageId.SetupWizard) {
          await this.setPages([
            { pageId: PageId.DeviceStats },
            { pageId: PageId.SetupWizard },
          ])
        } else {
          await this.setRoot(pageId)
          // silently connects BRNKL-Mate bluetooth in background
          await this.connectToBluetooth()
        }
      })
  }

  private async setRoot(pageId: PageId, params?: any) {
    if (this.currentPageId !== pageId) {
      const page = this.pageIdToPage(pageId)
      await this.router.navigate([page], {
        queryParams: params,
        replaceUrl: true,
      })

      this.currentPageId = pageId
    }
  }

  private async setPages(
    pagesById: Array<{
      pageId: PageId
      params?: any
    }>
  ) {
    const topPageId = pagesById[pagesById.length - 1].pageId

    if (topPageId !== this.currentPageId) {
      const pages = pagesById.map((pageById) => {
        return {
          page: this.pageIdToPage(pageById.pageId),
          params: pageById.params,
        }
      })
      // TODO: fix how to set pages with angular routing
      await this.navCtrl.navigateForward(pages)
      this.currentPageId = topPageId
    }
  }

  private async handleAddDeviceRoute(deviceId: string, nDevices: number) {
    // To avoid switching to the tabs page when a BRNKL pushes data
    // ensure the deviceId is set to null. Then load the select device page

    if (deviceId != null) {
      this.device.setCurrentDeviceId(null)
      return
    }

    if (nDevices > 0) {
      await this.setPages([
        { pageId: PageId.SelectDevice },
        { pageId: PageId.AddDevice, params: this.urlParams },
      ])
    } else {
      // Must use setRoot() to properly pass params to Add-Device page
      await this.setRoot(PageId.AddDevice, this.urlParams)
    }

    // Set undefined as we only want to navigate to the add device page once
    this.urlRoute = undefined
  }

  private pageIdToPage(pageId: PageId): string {
    if (pageId === PageId.DeviceStats) {
      return 'tabs'
    } else if (pageId === PageId.SelectDevice) {
      return 'select-device'
    } else if (pageId === PageId.UserLogin) {
      return 'login'
    } else if (pageId === PageId.AddDevice) {
      return 'add-device'
    } else if (pageId === PageId.ForceUpdate) {
      return 'force-update'
    } else {
      return 'loading'
    }
  }
}

const getRoute = (): Route => {
  try {
    // Parse URL
    const { href: url } = window.location
    const urlParts = url.split('/')
    if (
      urlParts[3] !== 'add-device' &&
      urlParts[3] !== 'app-deeplink' &&
      urlParts[3] !== 'add-satellite-service'
    ) {
      console.log('No data in params')
      return undefined
    }

    const route: Route = {
      method: urlParts[3],
      deviceId: urlParts[4],
    }
    return route
  } catch {
    return undefined
  }
}
