import { Injectable } from '@angular/core'
import { AngularFireDatabase } from '@angular/fire/compat/database'
import firebase from 'firebase/compat/app'
import { Observable, of } from 'rxjs'
import {
  filter,
  take,
  takeUntil,
  map,
  switchMap,
  catchError,
} from 'rxjs/operators'

import { AnchorConfig } from '../../models-shared/anchor-config.model'
import { MapConfig } from '../../models-shared/map-config.model'
import { DeviceSettings } from '../../models-shared/device-settings.model'
import { Sensor } from '../../models-shared/sensor.model'
import { UserSettings } from '../../models-shared/user-settings.model'
import { FunctionsRes } from '../../models-shared/functions-res.model'
import { AuthProvider } from '../auth/auth.service'
import { DbPathsProvider } from '../db-paths/db-paths.service'
import { DeviceProvider } from '../device/device.service'
import { CloudFunctionsProvider } from '../cloud-functions/cloud-functions.service'
import { BackendDeviceSettings } from '../../models-shared/backend-device-settings.model'
import { ValidateDeviceSettings } from '../../validators/device-settings.validator'
import { MateSettings } from '../../models-shared/mate-settings.model'
import { BehaviorSubject } from 'rxjs'
import { SatModemSettings } from '../../models-shared/satellite-modem-settings.model'

@Injectable({
  providedIn: 'root',
})
export class SettingsProvider {
  public sensorConfig$: Observable<{ [key: string]: Sensor }>
  public deviceSettings$: BehaviorSubject<DeviceSettings> = new BehaviorSubject(
    null
  )
  public userSettings$: BehaviorSubject<UserSettings> = new BehaviorSubject(
    null
  )
  public mateSettings$: BehaviorSubject<MateSettings> = new BehaviorSubject(
    null
  )
  public satelliteSettings$: BehaviorSubject<SatModemSettings> =
    new BehaviorSubject(null)

  private firebaseDeviceSettings$: Observable<DeviceSettings>
  private firebaseMateSettings$: Observable<MateSettings>
  private firebaseUserSettings$: Observable<UserSettings>
  private firebaseSatelliteSettings$: Observable<SatModemSettings>

  public maxWirelessSensors: boolean = false
  private maxWirelessSensorsCount = 15

  constructor(
    private db: AngularFireDatabase,
    private auth: AuthProvider,
    private device: DeviceProvider,
    private paths: DbPathsProvider,
    private cloudFunctions: CloudFunctionsProvider
  ) {
    this.subscribe()
  }

  private subscribe() {
    this.firebaseDeviceSettings$ = this.getDeviceSettings()
    this.firebaseDeviceSettings$.subscribe((deviceSettings: DeviceSettings) => {
      this.deviceSettings$.next(deviceSettings)
    })

    this.firebaseMateSettings$ = this.getMateSettings()
    this.firebaseMateSettings$.subscribe((mateSettings: MateSettings) => {
      this.updateMaxWirelessSensors(mateSettings)
      this.mateSettings$.next(mateSettings)
    })

    this.firebaseUserSettings$ = this.getUserSettings()
    this.firebaseUserSettings$.subscribe((userSettings: UserSettings) =>
      this.userSettings$.next(userSettings)
    )
    this.firebaseSatelliteSettings$ = this.getSatelliteSettings()
    this.firebaseSatelliteSettings$.subscribe(
      (satelliteSettings: SatModemSettings) => {
        this.satelliteSettings$.next(satelliteSettings)
      }
    )
  }

  public changeDevice(): void {
    this.device.setCurrentDeviceId(null)
    this.maxWirelessSensors = false
  }

  private sanitizeDeviceSettings(
    deviceSettings: DeviceSettings
  ): DeviceSettings {
    const {
      startTimes,
      password,
      lastAlerted,
      inited,
      pushNotificationTokens,
      linkedMate,
      motionConfig,
      factoryConfig,
      ...rest
    } = deviceSettings
    return rest
  }

  private checkShowOnGrid(deviceSettings: DeviceSettings) {
    if (deviceSettings && deviceSettings.sensorConfig) {
      const { sensorConfig } = deviceSettings
      const sensorKeys = Object.keys(sensorConfig)
      for (const sensor of sensorKeys) {
        if (sensorConfig[sensor].showOnGrid) {
          return
        }
      }

      // Every showOnGrid is false
      throw new Error('Atleast one BRNKL sensor must be shown on the grid')
    }
  }

  private sanitizeMateSettings(
    mateSettings: MateSettings
  ): Partial<MateSettings> {
    const { lastAlerted, inited, linkedBrnkl, startTimes, ...rest } =
      mateSettings
    return rest
  }

  private checkMapGPSPoints(deviceSettings: DeviceSettings) {
    if (
      deviceSettings.mapConfig &&
      deviceSettings.mapConfig.maxGpsPoints > 1500
    ) {
      throw new Error('The Max GPS Points must be set below 1500.')
    }
  }

  public saveDeviceSettings(deviceSettings: DeviceSettings): Promise<void> {
    const deviceId: string =
      this.device.currentBRNKLandMateId$.getValue().deviceId

    const sanitizedSettings = this.sanitizeDeviceSettings(deviceSettings)
    this.checkShowOnGrid(sanitizedSettings)
    this.checkMapGPSPoints(sanitizedSettings)

    return this.db
      .object(this.paths.deviceSettings(deviceId))
      .update(sanitizedSettings)
  }

  public saveMateSettings(mateSettings: MateSettings): Promise<void> {
    const mateId: string = this.device.currentBRNKLandMateId$.getValue().mateId

    const sanitizeMateSettings = this.sanitizeMateSettings(mateSettings)

    return this.cloudFunctions.authedPost(
      `mate/settings/${mateId}`,
      sanitizeMateSettings
    )
  }

  public removeWirelessSensorFromMateSettings(): Promise<void> {
    const mateId: string = this.device.currentBRNKLandMateId$.getValue().mateId

    return this.cloudFunctions.authedDel(`mate/settings/${mateId}`)
  }

  public async setIsArmed(
    isArmedStatus: boolean,
    skipNotifyBrnkl: boolean
  ): Promise<boolean> {
    const deviceId = this.device.currentBRNKLandMateId$.value.deviceId
    return this.cloudFunctions.authedPost<boolean>(`isArmed/${deviceId}`, {
      isArmedStatus,
      skipNotifyBrnkl,
    })
  }

  private sanitizeUserSettings(
    userSettings: UserSettings
  ): Partial<UserSettings> {
    const { devices, permissionLevel, ...rest } = userSettings
    return rest
  }

  public async saveUserSettings(settings: UserSettings): Promise<any> {
    const user: firebase.User = await this.auth.user.pipe(take(1)).toPromise()
    if (!user || !user.uid) throw Error('Cannot get UID')
    return this.db
      .object(this.paths.userSettings(user.uid))
      .update(this.sanitizeUserSettings(settings))
  }

  private getUserSettings(): Observable<UserSettings> {
    return this.auth.user.pipe(
      filter((user) => user != null),
      filter((user) => user.uid != null),
      map((user) => user.uid),
      switchMap((uid: string) => {
        return this.db
          .object(this.paths.userSettings(uid))
          .valueChanges()
          .pipe(takeUntil(this.auth.logouts$))
      })
    )
  }

  public isViewer(): boolean {
    const permissionLevel = this.userSettings$.value.permissionLevel ? this.userSettings$.value.permissionLevel : 0
    return permissionLevel === 1
  }

  public clearMap(): Promise<void> {
    return this.setMapConfig({
      lastGpsResetDatetime: Date.now() / 1000.0,
    })
  }

  public setMapConfig(mapConfig: MapConfig): Promise<void> {
    const deviceId: string =
      this.device.currentBRNKLandMateId$.getValue().deviceId
    return this.db.object(this.paths.mapConfig(deviceId)).update(mapConfig)
  }

  public setAnchorConfig(anchorConfig: AnchorConfig): Promise<void> {
    return this.saveDeviceSettings({
      anchorConfig,
    })
  }

  public async calibrateAccelerometer(): Promise<boolean> {
    const deviceId: string =
      this.device.currentBRNKLandMateId$.getValue().deviceId
    const res: FunctionsRes<any> = await this.cloudFunctions.authedPost<
      FunctionsRes<any>
    >(`calibrate/${deviceId}`)
    return res.success
  }

  public getBackendSettings(): Observable<BackendDeviceSettings> {
    return this.device.currentBRNKLandMateId$.pipe(
      map((ids) => ids.deviceId),
      filter((deviceId) => deviceId != null),
      switchMap((deviceId: string) =>
        this.db
          .object<BackendDeviceSettings>(this.paths.backendSettings(deviceId))
          .valueChanges()
          .pipe(takeUntil<BackendDeviceSettings>(this.device.deviceUnselected$))
      )
    )
  }

  public getDeviceSettings(): Observable<DeviceSettings> {
    return this.device.currentBRNKLandMateId$.pipe(
      map((ids) => ids.deviceId),
      filter((deviceId) => deviceId != null),
      switchMap((deviceId: string) =>
        this.db
          .object(this.paths.deviceSettings(deviceId))
          .valueChanges()
          .pipe(
            map((settings: DeviceSettings) => ValidateDeviceSettings(settings)),
            takeUntil(this.device.deviceUnselected$)
          )
      )
    )
  }

  private getMateSettings(): Observable<MateSettings> {
    return this.device.currentBRNKLandMateId$.pipe(
      map((ids) => ids.mateId),
      filter((mateId) => mateId != null),
      switchMap((mateId: string) => {
        if (mateId != null) {
          return this.db
            .object<MateSettings>(this.paths.mateSettings(mateId))
            .valueChanges()
            .pipe(
              takeUntil(this.device.mateChanged$),
              catchError((err) => of(null))
            ) // Handle when mate is removed (permissions revoked)
        } else {
          return of(null)
        }
      })
    )
  }

  private getSatelliteSettings(): Observable<SatModemSettings> {
    return this.device.currentBRNKLandMateId$.pipe(
      map((ids) => ids.satModemId),
      filter((satelliteId) => satelliteId != null),
      switchMap((satelliteId: string) => {
        if (satelliteId != null) {
          return this.db
            .object<SatModemSettings>(this.paths.satModemSettings(satelliteId))
            .valueChanges()
            .pipe(
              takeUntil(this.device.satModemChanged$),
              catchError((err) => of(null))
            ) // Handle when satellite is removed (permissions revoked)
        } else {
          return of(null)
        }
      })
    )
  }

  //  Adds the wireless sensor data to database
  public async addWirelessSensor(
    mateId: string,
    sensorName: string,
    sensorType: number,
    macAddress: string,
    installCode: string,
    manufacturer: string
  ): Promise<FunctionsRes<{ sensorNameUpdatedOnly: boolean }>> {
    const body = {
      sensorName,
      sensorType,
      macAddress,
      installCode,
      manufacturer,
    }
    const res = await this.cloudFunctions.authedPost<
      FunctionsRes<{
        sensorNameUpdatedOnly: boolean
      }>
    >(`mate/sensor/${mateId}`, body)
    return res
  }

  public async removeWirelessSensor(
    mateId: string,
    sensorKey: string
  ): Promise<boolean> {
    const body = {
      sensorKey,
    }
    const res: FunctionsRes<void> = await this.cloudFunctions.authedDel<
      FunctionsRes<void>
    >(`mate/sensor/${mateId}`, body)

    return res.success
  }

  private updateMaxWirelessSensors = (mateSettings: MateSettings) => {
    if (mateSettings && mateSettings.sensorConfig) {
      this.maxWirelessSensors =
        Object.keys(mateSettings.sensorConfig).length >=
        this.maxWirelessSensorsCount
    }
  }
}
