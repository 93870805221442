import { Component, ViewEncapsulation } from '@angular/core'
import { select } from '@angular-redux/store'
import { ModalController } from '@ionic/angular'
import { Observable } from 'rxjs'
import { BehaviorSubject } from 'rxjs'
import { map, startWith } from 'rxjs/operators'

import { Photo } from '../../models/photo.model'

import { PhotosProvider } from '../../services/photos/photos.service'
import { HelpersProvider } from '../../services/helpers/helpers.service'
import { AuthProvider } from '../../services/auth/auth.service'
import { CloudFunctionsProvider } from '../../services/cloud-functions/cloud-functions.service'
import { SettingsProvider } from '../../services/settings/settings.service'

import { PhotoModalComponent } from '../../components/photo-modal/photo-modal.component'

import { formatDate } from '../../reducers/root-reducer/datetime-reducer'

@Component({
  selector: 'app-photos',
  templateUrl: './photos.page.html',
  styleUrls: ['./photos.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PhotosPage {
  @select(['currentDevice', 'photos'])
  visiblePhotos$: Observable<Photo[]>

  visiblePhotosWithUrl$: Observable<Photo[]>
  lastUpdated$: Observable<string>

  private photoCache: { [filename: string]: string } = {}

  constructor(
    private photosProvider: PhotosProvider,
    private settingsProvider: SettingsProvider,
    private modalCtrl: ModalController,
    private auth: AuthProvider,
    private helpers: HelpersProvider,
    private cloudFunctions: CloudFunctionsProvider
  ) {
    this.setupObservables()
  }

  private setupObservables() {
    this.visiblePhotosWithUrl$ = this.visiblePhotos$.pipe(
      map((photos) => {
        if (photos) {
          return photos.map((photo) => {
            // Behaviour subject to save URL for photo modal
            photo.url$ = new BehaviorSubject<string>(undefined)
            this.getPhotoUrl(photo).then((url) => photo.url$.next(url))
            return photo
          })
        } else {
          return photos
        }
      })
    )

    this.lastUpdated$ = this.photosProvider.photos$
      .pipe(
        map((photos) => {
          if (photos.length <= 0) {
            return 'N/A'
          }

          const datetimes: number[] = photos
            .filter(({ datetime }) => datetime != null)
            .map(({ datetime }) => datetime)
          const datetime = Math.max(...datetimes)
          return formatDate(datetime)
        })
      )
      .pipe(startWith('N/A'))
  }

  private changeBlobtoBase64 = async (imageBlob: Blob): Promise<string> => {
    const reader = new FileReader()
    return new Promise<string>((resolve, reject) => {
      reader.onloadend = (): void => resolve(<string>reader.result)
      reader.onerror = (): void => reject(new Error('Reader error'))
      reader.readAsDataURL(imageBlob)
    })
  }

  private getPhotoUrl = async (photo: Photo): Promise<string> => {
    const { deviceId, camId, filename } = photo
    try {
      if (this.photoCache[filename] == null) {
        const endpointURL = `photo/${deviceId}?camId=${camId}&fileName=${filename}`
        const imageResponse: Blob = await this.cloudFunctions.authedGetImage(
          endpointURL
        )
        const base64Image: string = await this.changeBlobtoBase64(imageResponse)
        this.photoCache[filename] = base64Image
      }

      return this.photoCache[filename]
    } catch {
      photo.rotation = 0
      return './assets/noimage.png'
    }
  }

  async openModal(photo: any): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: PhotoModalComponent,
      componentProps: photo, //This might not work
    })
    modal.present()
  }

  async scrollDown(scroll): Promise<void> {
    await this.photosProvider.appendToVisiblePhotos()
    scroll.complete()
  }

  async requestPhoto(): Promise<void> {
    if (this.auth.isDemoAccount()) {
      this.helpers.showToast('Taking photos is not available in demo mode.')
      return
    }

    if (this.settingsProvider.isViewer()) {
      this.helpers.showToast('Not available as a Viewer.')
      return
    }

    try {
      const { success } = await this.photosProvider.requestPhoto()
      if (!success) throw new Error('Failed on server')
      this.helpers.stopLoading()
      this.helpers.showToast('Taking photo\n\nWait 2 minutes to view.')
    } catch (e) {
      this.helpers.stopLoading()
      this.helpers.showDangerToast('Request failed')
    }
  }
}
