// Angular dependencies
import { AgmCoreModule } from '@agm/core'
import { NgRedux, NgReduxModule } from '@angular-redux/store'
import { ErrorHandler, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

// Ionic dependencies
import { AppVersion } from '@ionic-native/app-version/ngx'
import { SplashScreen } from '@ionic-native/splash-screen/ngx'
import { StatusBar } from '@ionic-native/status-bar/ngx'
import { IonicModule, NavParams } from '@ionic/angular'
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx'
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx'
import { BluetoothLE } from '@ionic-native/bluetooth-le/ngx'
import { File } from '@ionic-native/file/ngx'
import { Insomnia } from '@ionic-native/insomnia/ngx'

// Firebase dependencies
import { AngularFireModule } from '@angular/fire/compat'
import { AngularFireAuthModule } from '@angular/fire/compat/auth'
import { AngularFireDatabaseModule } from '@angular/fire/compat/database'

import { createLogger } from 'redux-logger'

import { ENV } from '@app/env'

// Chart module
import { NgChartsModule } from 'ng2-charts'
import { SwiperModule } from 'swiper/angular'

// Components
import { AlertsPopoverComponent } from './components/alerts-popover/alerts-popover.component'
import { BottomPanelComponent } from './components/bottom-panel/bottom-panel.component'
import { ConfigureSensorsComponent } from './components/configure-sensors/configure-sensors.component'
import { PanelContainerComponent } from './components/panel-container/panel-container.component'
import { PhotoModalComponent } from './components/photo-modal/photo-modal.component'
import { SetAnchorComponent } from './components/set-anchor/set-anchor.component'
import { SetPasswordModalComponent } from './components/set-password-modal/set-password-modal.component'
import { SetPasswordComponent } from './components/set-password/set-password.component'
import { SignalIndicatorComponent } from './components/signal-indicator/signal-indicator.component'
import { StatsGridComponent } from './components/stats-grid/stats-grid.component'
import { DeviceGridComponent } from './components/device-grid/device-grid.component'
import { TopPanelComponent } from './components/top-panel/top-panel.component'
import { AppComponent } from './app.component'
import { CardPaymentComponent } from './components/card-payment/card-payment.component'
import { SubscriptionComponent } from './components/subscription/subscription.component'
import { StripeCardComponent } from './components/stripe-card/stripe-card.component'
import { CardRadioComponent } from './components/card-radio/card-radio.component'
import { CardComponent } from './components/card/card.component'
import { TextInputComponent } from './components/text-input/text-input.component'
import { SearchBarComponent } from './components/search-bar/search-bar.component'
import { OptionalTextComponent } from './components/optional-text/optional-text.component'
import { CheckoutPriceComponent } from './components/checkout-price/checkout-price.component'
import { SearchDeviceComponent } from './components/search-device/search-device.component'
import { ForgetPasswordModalComponent } from './components/forget-password-modal/forget-password-modal.component'
import { FilterAlertsModalComponent } from './components/filter-alerts-modal/filter-alerts-modal.component'
import { TopBarTitleComponent } from './components/top-bar-title/top-bar-title.component'
import { ArmDisarmButtonComponent } from './components/arm-disarm-button/arm-disarm-button.component'
import { SetupContainerComponent } from './components/setup-container/setup-container.component'
import { SetupInfoComponent } from './components/setup-info/setup-info.component'
import { SetupPaymentComponent } from './components/setup-payment/setup-payment.component'
import { SetupConfirmComponent } from './components/setup-confirm/setup-confirm.component'
import { SetupOptionsComponent } from './components/setup-options/setup-options.component'
import { SetupDoneStepComponent } from './components/setup-done-step/setup-done-step.component'
import { SetupWirelessInitComponent } from './components/setup-wireless-init/setup-wireless-init.component'
import { SetupWirelessActivateComponent } from './components/setup-wireless-activate/setup-wireless-activate.component'
import { SetupInputComponent } from './components/setup-input/setup-input.component'
import { SetupProgressComponent } from './components/setup-progress/setup-progress.component'
import { AddWirelessSensorModalComponent } from './components/add-wireless-sensor-modal/add-wireless-sensor-modal.component'
import { ReauthModalComponent } from './components/reauth-modal/reauth-modal.component'
import { UpdateMateModalComponent } from './components/update-mate-modal/update-mate-modal.component'
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component'
import { LinkMateModal } from './components/link-mate-modal/link-mate-modal.component'
import { LinkSatelliteModal } from './components/link-satellite-modal/link-satellite-modal.component'

import { SetupDeviceBox } from './components/setup-device-box/setup-device-box.component'
import { SetupServiceBox } from './components/setup-service-box/setup-service-box.component'
import { SettingsAddDeviceModal } from './components/settings-add-device-modal/settings-add-device-modal.component'
import { AddServiceModal } from './components/add-service-modal/add-service-modal.component'
import { ChangeServiceModal } from './components/change-service-modal/change-service-modal.component'
import { DeviceTransferModal } from './components/device-transfer-modal/device-transfer-modal.component'
import { BluetoothButtonComponent } from './components/bluetooth-button/bluetooth-button.component'
import { VideoComponent } from './components/video/video'

// Models
import { DEFAULT_STATE, State } from './models/state.model'

// Pages
import { AddDevicePage } from './pages/add-device/add-device.page'
import { AlertDetailsPage } from './pages/alert-details/alert-details.page'
import { AlertsPage } from './pages/alerts/alerts.page'
import { CreateAccountPage } from './pages/create-account/create-account.page'
import { GraphsPage } from './pages/graphs/graphs.page'
import { LayoutPage } from './pages/layout/layout.page'
import { LoginPage } from './pages/login/login.page'
import { MapPage } from './pages/map/map.page'
import { PhotosPage } from './pages/photos/photos.page'
import { SandboxPage } from './pages/sandbox/sandbox.page'
import { SelectDevicePage } from './pages/select-device/select-device.page'
import { SettingsPage } from './pages/settings/settings.page'
import { TabsPage } from './pages/tabs/tabs.page'
import { SubscriptionPage } from './pages/subscription/subscription.page'
import { LoadingPage } from './pages/loading/loading.page'
import { ForceUpdatePage } from './pages/force-update/force-update.page'

// Pipes
import { ConvertValuePipe } from './pipes/convert-value/convert-value.pipe'
import { ConvertLongitudePipe } from './pipes/convert-gps/convert-long'
import { ConvertLatitudePipe } from './pipes/convert-gps/convert-lat'

// services
import { AlertsProvider } from './services/alerts/alerts.service'
import { AuthProvider } from './services/auth/auth.service'
import { CloudFunctionsProvider } from './services/cloud-functions/cloud-functions.service'
import { DbPathsProvider } from './services/db-paths/db-paths.service'
import { DeviceProvider } from './services/device/device.service'
import { HelpersProvider } from './services/helpers/helpers.service'
import { NavDataService } from './services/navigation/navigation.service'
import { PhotosProvider } from './services/photos/photos.service'
import { SettingsProvider } from './services/settings/settings.service'
import { SideEffectsProvider } from './services/side-effects/side-effects.service'
import { StatsProvider } from './services/stats/stats.service'
import { LocalizationService } from './services/localization/localization.service'
import { NotificationsProvider } from './services/notifications/notifications.service'
import { SubscriptionProvider } from './services/subscription/subscription.service'
import { AnalyticsProvider } from './services/analytics/analytics.service'
import { EnvironmentProvider } from './services/environment/environment.service'
import { FirebaseProvider } from './services/firebase/firebase.service'
import { BluetoothLEProvider } from './services/bluetooth-le/bluetooth-le.service'
import { BluetoothLEAdapter } from './services/bluetooth-le/ble-adapter.service'
import { AppUpdateProvider } from './services/app-update/app-update.service'
import { UpdateMateProvider } from './services/update-mate/update-mate.service'
import { HTTP } from '@ionic-native/http/ngx'

// Reducers
import { rootReducer } from './reducers'
import { HttpClientModule } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AppRoutingModule } from './app-routing.module'
import { RouterModule } from '@angular/router'
import { DeleteAccountModalComponent } from './components/delete-account-modal/delete-account-modal.component'
import { VideoPage } from './pages/video/video.page'
import { VideoPlayerModalComponent } from './components/video-player-modal/video-player-modal.component'
import { HlsService } from './services/hls/hls.service'

// Pipes
import { OrderGraphsPipe } from './pages/graphs/graph-pipe'

export const firebaseConfig = {
  apiKey: ENV.FIRE_API_KEY,
  authDomain: ENV.FIRE_AUTH_DOMAIN,
  databaseURL: ENV.FIRE_DATABASE_URL,
  projectId: ENV.FIRE_PROJECT_ID,
  storageBucket: ENV.FIRE_STORAGE_BUCKET,
  messagingSenderId: ENV.FIRE_SENDER_ID,
}

@NgModule({
  declarations: [
    AppComponent,
    LoginPage,
    CreateAccountPage,
    TabsPage,
    MapPage,
    GraphsPage,
    PhotosPage,
    AlertsPage,
    AlertDetailsPage,
    SettingsPage,
    AddDevicePage,
    SandboxPage,
    SelectDevicePage,
    SubscriptionPage,
    LoadingPage,
    ForceUpdatePage,
    AlertsPopoverComponent,
    FilterAlertsModalComponent,
    StatsGridComponent,
    DeviceGridComponent,
    SignalIndicatorComponent,
    SetAnchorComponent,
    ConfigureSensorsComponent,
    TopPanelComponent,
    BottomPanelComponent,
    PanelContainerComponent,
    ConvertValuePipe,
    ConvertLatitudePipe,
    ConvertLongitudePipe,
    DeleteAccountModalComponent,
    SetPasswordComponent,
    SetPasswordModalComponent,
    PhotoModalComponent,
    CardPaymentComponent,
    SubscriptionComponent,
    StripeCardComponent,
    CardRadioComponent,
    CardComponent,
    TextInputComponent,
    SearchBarComponent,
    OptionalTextComponent,
    CheckoutPriceComponent,
    SearchDeviceComponent,
    ForgetPasswordModalComponent,
    TopBarTitleComponent,
    ArmDisarmButtonComponent,
    SetupContainerComponent,
    SetupInfoComponent,
    SetupPaymentComponent,
    SetupConfirmComponent,
    SetupOptionsComponent,
    SetupDoneStepComponent,
    SetupWirelessInitComponent,
    SetupWirelessActivateComponent,
    SetupInputComponent,
    SetupProgressComponent,
    AddWirelessSensorModalComponent,
    ReauthModalComponent,
    UpdateMateModalComponent,
    ProgressBarComponent,
    LinkMateModal,
    LinkSatelliteModal,
    SetupDeviceBox,
    SetupServiceBox,
    SettingsAddDeviceModal,
    AddServiceModal,
    ChangeServiceModal,
    BluetoothButtonComponent,
    DeviceTransferModal,
    VideoPage,
    VideoPlayerModalComponent,
    VideoComponent,
    OrderGraphsPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    NgChartsModule,
    SwiperModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      scrollAssist: true,
      scrollPadding: false,
    }),
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireModule,
    AngularFireAuthModule,
    AgmCoreModule.forRoot({
      apiKey: ENV.GOOGLE_MAPS_KEY,
    }),
    FormsModule,
    ReactiveFormsModule,
    NgReduxModule,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    LayoutPage,
    AppComponent,
    LoginPage,
    CreateAccountPage,
    MapPage,
    TabsPage,
    GraphsPage,
    PhotosPage,
    AlertsPage,
    AlertDetailsPage,
    SettingsPage,
    AddDevicePage,
    SelectDevicePage,
    SandboxPage,
    SubscriptionPage,
    LoadingPage,
    ForceUpdatePage,
    AlertsPopoverComponent,
    FilterAlertsModalComponent,
    SetPasswordModalComponent,
    PhotoModalComponent,
    ForgetPasswordModalComponent,
    AddWirelessSensorModalComponent,
    UpdateMateModalComponent,
    ReauthModalComponent,
    LinkMateModal,
    LinkSatelliteModal,
    SettingsAddDeviceModal,
    AddServiceModal,
    ChangeServiceModal,
    DeviceTransferModal,
  ],
  providers: [
    File,
    StatusBar,
    SplashScreen,
    { provide: ErrorHandler, useClass: ErrorHandler },
    AuthProvider,
    PhotosProvider,
    AlertsProvider,
    HelpersProvider,
    NavDataService,
    SettingsProvider,
    CloudFunctionsProvider,
    AppVersion,
    NavParams,
    ConvertValuePipe,
    ConvertLatitudePipe,
    ConvertLongitudePipe,
    StatsProvider,
    DeviceProvider,
    SideEffectsProvider,
    DbPathsProvider,
    NotificationsProvider,
    SubscriptionProvider,
    LocalizationService,
    AnalyticsProvider,
    EnvironmentProvider,
    BarcodeScanner,
    InAppBrowser,
    FirebaseProvider,
    BluetoothLE,
    BluetoothLEProvider,
    BluetoothLEAdapter,
    AppUpdateProvider,
    Insomnia,
    UpdateMateProvider,
    HTTP,
    HlsService,
  ],
})
export class AppModule {
  constructor(ngRedux: NgRedux<State>) {
    const middleware: any[] = ENV.IS_PROD ? [] : [createLogger()]
    ngRedux.configureStore(rootReducer, DEFAULT_STATE, middleware)
  }
}
