import { select } from '@angular-redux/store'
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core'
import { Observable } from 'rxjs'

import { AppDevice } from '../../models/app-device.model'
import { BehaviorSubject } from 'rxjs'
import { UserSettings } from '../../models-shared/user-settings.model'
import { SettingsProvider } from '../../services/settings/settings.service'
import { Stat } from 'app/models-shared/stat.model'
import { offlineTimeout } from 'app/util'

@Component({
  selector: 'stats-grid',
  templateUrl: './stats-grid.component.html',
  styleUrls: ['./stats-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StatsGridComponent {
  @select('currentDevice') currentDevice$: Observable<AppDevice>
  @Input() expanded: boolean
  @Output() expandedChange: EventEmitter<boolean> = new EventEmitter()
  public userSettings: BehaviorSubject<UserSettings>

  constructor(private settingsProvider: SettingsProvider) {
    this.userSettings = this.settingsProvider.userSettings$
  }

  public getWirelessSensorPreferredTempUnit = () => {
    return this.userSettings.value.preferredTempUnit
  }

  toggleOpen(): void {
    this.expanded = !this.expanded
    this.expandedChange.emit(this.expanded)
  }

  getStatKey(index, stat: Stat<any>): string {
    return stat.key
  }

  isStale(statDatetime: string): boolean {
    return +statDatetime <= offlineTimeout
  }

  sortedStats(stats: any[]): any[] {
    return stats.sort((a, b) => {
      const isStaleA = this.isStale(a.datetime) ? 1 : 0
      const isStaleB = this.isStale(b.datetime) ? 1 : 0
      return isStaleA - isStaleB
    })
  }
}
